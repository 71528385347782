@import 'variables';

.gac-free-images,
.gac-library {
  padding-top: 80px;
  padding-bottom: 121px;
  &.gac-free-images-empty {
    display: flex;
    flex-direction: column;
    &.gac-mobile-view {
      padding-bottom: 15px;
    }
  }
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 30px;
  }
  .gac-images-search {
    display: flex;
    margin-bottom: 24px;
    transition: margin-bottom .2s ease;
    &.gac-filters-on {
      margin-bottom: 16px;
    }
    .gac-search-input-wrap {
      position: relative;
      flex: 1 1 auto;
      .gac-search-input {
        width: 100%;
        height: 48px;
        line-height: normal;
        padding: 0 180px 0 15px;
        border: 1px solid $border-color;
        border-radius: 2px;
        color: $dark-text-color;
        font-size: 15px;
        box-shadow: none!important;
        background-clip: padding-box;
        &::-webkit-input-placeholder {
          color: $light-text-color;
        }
        &::-moz-placeholder {
          color: $light-text-color;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: $light-text-color;
        }
        &:-moz-placeholder {
          color: $light-text-color;
          opacity: 1;
        }
      }
      .gac-search__total {
        position: absolute;
        right: 56px;
        top: 12px;
        line-height: 22px;
        color: $light-text-color;
        font-size: 15px;
      }
      .gac-search__btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        background: url('../../images/sprite.svg') no-repeat -115px 0;
        cursor: pointer;
      }
    }
    .gac-filters-btn {
      margin-left: 16px;
    }
  }
  .gac-images-filters {
    display: flex;
    .gac-filter-item {
      width: 190px;
      margin-right: 16px;
      margin-bottom: 24px;
    }
    .gac-filter-item__safesearch {
      margin-left: auto;
      input {
        display: none;
      }
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        //background: url('../../images/sprite.svg') -40px -90px;
      }
      input:checked + i {
        //background-position: -40px -110px;
      }
      label {
        position: relative;
        display: block;
        line-height: 48px;
        padding-left: 28px;
        cursor: pointer;
        color: $dark-text-color;
        font-size: 15px;
        white-space: nowrap;
      }
    }
  }
  .gac-categories-carousel-wrap {
    line-height: 0;
    user-select: none;
    * {
      user-select: none;
    }
    .gac-carousel-btns {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      margin-top: -20px;
      background: url('../../images/sprite.svg') -115px -70px white;
      border: 1px solid $border-color;
      border-radius: 100%;
      text-indent: -9999999px;
      opacity: 1;
      visibility: visible;
      transition: opacity .2s ease;
      z-index: 1;
      cursor: pointer;
      &.gac-prev {
        left: 5px;
      }
      &.gac-next {
        right: 5px;
        background-position: -115px -110px;
      }
      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        display: block!important;
      }
    }
    .gac-carousel-item {
      position: relative;
      //width: 182px;
      //padding: 0 4px;
      cursor: pointer;
      img {
        border-radius: 2px;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(52,55,68,0.5);
        color: $white-color;
        font-size: 15px;
        text-align: center;
        border-radius: 2px;
      }
    }
  }
  .gac-free-images-wrap {
    position: relative;
    margin: 0 0 44px;
  }
  .gac-free-images-pagination-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .gac-free-images-text {
      p {
        margin: 0 0 7px 0;
        line-height: 16px;
        color: $light-text-color;
        font-size: 12px;
        a {
          text-decoration: none;
          color: $green-color;
          &:hover {
            text-decoration: underline;
          }
        }
        i {
          display: inline-block;
          width: 14px;
          height: 10px;
          margin: 0 10px 0 4px;
          background: url('../../images/sprite.svg') no-repeat -60px -120px;
        }
      }
    }
    .gac-free-images-pagination {
      display: flex;
      input {
        width: 40px;
        height: 40px;
        padding: 0 6px;
        margin: 0 6px 0 34px;
        border: 1px solid $border-color;
        border-radius: 2px;
        color: $dark-text-color;
        font-size: 15px;
        text-align: center;
        box-shadow: none!important;
        background-clip: padding-box;
      }
      .gac-free-images__total-pages {
        line-height: 40px;
        margin: 0 34px 0 0;
        color: $light-text-color;
        font-size: 15px;
      }
      .gac-btn {
        font-size: 16px;
        &:not(.gac-disabled) {
          &:before {
            background: #59C69F;
          }
        }
      }
    }
  }
  .gac-empty-state {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.gac-filters-btns {
  padding-bottom: 24px;
  margin: auto 15px 0 15px;
  display: flex;
  justify-content: space-between;
  .gac-btn, .gac-btn-v2 {
    min-width: 100px;
    width: calc(50% - 4px);
  }
  .gac-btn-v2:before {
    background-color: $bg-color;
  }
}
.gac-free-images__item {
  position: absolute;
  opacity: 0;
  transition: opacity .2s ease;
  border-radius: 2px;
  &.gac-loaded {
    opacity: 1;
  }
  &.gac-error {
    opacity: 1;
    background-color: $border-color;
    .gac-free-images__item-btns {
      display: none!important;
    }
  }
  &:hover > span {
    opacity: 1;
  }
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    right: 14px;
    bottom: 16px;
    background-color: $green-color;
    border-radius: 100%;
    opacity: 0;
    cursor: pointer;
    transition: opacity .2s ease-in-out;
  }
  img {
    border-radius: 2px;
  }
  .gac-free-images__item-btns {
    display: flex;
    position: absolute;
    right: 14px;
    bottom: 16px;
    .gac-free-images-btns-wrap {
      position: relative;
      //&:first-child {
      //  margin-right: 8px;
      //}
    }
  }
}
.gac-plus-btn,
.gac-download-btn {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: $white-color;
  transition: background-color .2s ease;
  border-radius: 100%;
  z-index: 1;
  overflow: hidden;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $green-color;
    opacity: 1;
    transition: opacity .2s ease;
    content: '';
    z-index: -1;
  }
  &:hover {
    &:before {
      opacity: 0.8;
    }
  }
  &.gac-plus-btn-s,
  &.gac-download-btn-s {
    width: 40px;
    height: 40px;
  }
  i {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url('../../images/sprite.svg') no-repeat -85px -180px;
    z-index: 2;
  }
  &.gac-download-btn {
    i {
      width: 20px;
      height: 20px;
      background-position: -115px -50px;
    }
  }
}
.gac-choose-size-menu {
  position: absolute;
  width: 250px;
  padding: 22px 0 15px;
  border: 1px solid $border-color;
  background-color: $white-color;
  box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
  border-radius: 2px;
  z-index: 2;
  p {
    padding: 0 15px;
    margin-bottom: 8px;
    color: $light-text-color;
    font-size: 12px;
  }
  ul {
    li {
      .gac-choose-size-menu__item {
        position: relative;
        display: flex;
        line-height: 37px;
        padding: 0 16px;
        text-decoration: none;
        font-size: 15px;
        color: $dark-text-color;
        transition: color .2s ease;
        z-index: 1;
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $green-color;
          opacity: 0;
          transition: opacity .2s ease;
          content: '';
          z-index: -1;
        }
        &:hover {
          color: $white-color;
          &:before {
            opacity: 1;
          }
        }
        span {
          &:first-child {
            width: 106px;
          }
          &:nth-child(2) {
            text-transform: uppercase;
          }
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
    &:not(.pixabay) {
      li .gac-choose-size-menu__item span:last-child {
        margin-left: inherit;
      }
    }
  }
}

.gac-library {
  padding: 0;
  .gac-categories-carousel-wrap {
    .gac-prev {
      left: -15px;
    }
    .gac-next {
      right: -15px;
    }
  }
}
/* ResourceSelect */
.gac-library__resourse {
  margin-bottom: 24px;
  label {
    display: block;
    line-height: 22px;
    margin-bottom: 11px;
    color: $dark-text-color;
    font-size: 15px;
  }
}
.gac-library__resourse-wrap {
  display: flex;
}
.gac-library__resourse-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 160px;
  height: 80px;
  margin-right: 24px;
  border: 1px solid #D9D9D9;
  background-color: $white-color;
  border-radius: 2px;
  cursor: pointer;
  &.gac-active {
    &:before {
      position: absolute;
      left: -1px;
      bottom: -1px;
      right: -1px;
      height: 4px;
      background-color: $green-color;
      border-radius: 2px;
      content: "";
    }
  }
  img {
    height: auto;
  }
}