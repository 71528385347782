@import "variables";

/* Body */
body,
a,
button,
input,
textarea,
.redactor-styles {
    font-family: $font;
}
body {
    position: relative;
    height: auto;
    min-height: 100vh;
    background: $bg-color;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $dark-text-color;
    scrollbar-gutter: stable;

    &.gac-no-touch-events {
        touch-action: none;
        overflow: hidden !important;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

/* Container */
.main-container {
    max-width: 1140px;
    margin: 0 auto;
}

/* Alignments */
.gac-align-right {
    margin-left: auto;
}

/* Gradient */
.bg-green-gradient {
    background: $green-color;
}

/* Elements */
a {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: underline;
    color: $dark-text-color;
    transition: color 0.2s ease;
    &:hover,
    &:active,
    &:focus {
        color: $green-color;
        outline: none;
        text-decoration: none;
    }
}

a.green {
    color: $green-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.gac-transaction-fee {
    position: relative;
    line-height: 27px;
    padding: 0 20px;
    font-size: 18px;
    text-align: center;
    z-index: 10;
}
