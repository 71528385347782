@import 'variables';

.gac-order-page {
  display: flex;
  align-items: flex-start;
  padding-top: 80px;
  padding-bottom: 130px;
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 30px;
  }
  &.gac-empty-cart {
    justify-content: center;
    .gac-order-step-create,
    .gac-new-project,
    .gac-order-projects-wrap {
      max-width: 780px;
      margin-right: 0;
    }
    //.gac-new-project {
    //  .gac-form-row {
    //    max-width: 716px;
    //  }
    //}
  }
  .gac-order-step-create {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    margin-right: 30px;
    background-color: $white-color;
    border: 2px dashed $border-color;
    border-radius: 2px;
    .gac-create-icon {
      margin-bottom: 28px;
      svg {
        vertical-align: top;
      }
    }
    p {
      line-height: 50px;
      margin-bottom: 32px;
      color: $dark-text-color;
      font-size: 31px;
      font-weight: bold;
    }
    .gac-btn {
      min-width: 250px;
      margin-bottom: 16px;
    }
    .gac-btn + .gac-btn-v2 {
      margin-bottom: 16px;
    }
    .gac-btn-v2 {
      min-width: 250px;
    }
  }
}
.gac-new-project {
  position: relative;
  flex: 1 1 auto;
  padding: 38px 31px;
  min-height: 800px;
  margin-right: 30px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  overflow: hidden;
  .gac-close-icon {
    position: absolute;
    right: 11px;
    top: 11px;
  }
  .gac-new-project-title {
    line-height: 28px;
    margin-bottom: 24px;
    color: $dark-text-color;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    span {
      color: $light-text-color;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .gac-form-row {
    margin-bottom: 24px;
    &.gac-row-multiple {
      display: flex;
      align-items: flex-start;
    }
    &.gac-row-multiple.gac-row-order_on {
      margin-bottom: 8px;
      flex-wrap: wrap;
      .gac-form-field {
        margin-bottom: 16px;
        &:nth-child(4n) {
          .gac-daypicker-wrap {
            left: auto;
            right: 0;
          }
        }
      }
    }
    &.gac-row-job-type {
      .gac-form-field {
        .gac-form-radio {
          flex-wrap: wrap;
        }
      }
    }
    &.gac-row-turnaround {
      .gac-form-field {
        .gac-form-field-content {
          flex-wrap: wrap;
          .gac-hint {
            width: 100%;
            margin-top: 9px;
            margin-left: 0;
            .gac-hint-text {
              transform: none;
              left: -113px;
              &:before {
                left: 121px;
              }
            }
          }
        }
      }
    }
    &.gac-row-topics-one-day-checkbox {
      margin-top: -14px;
    }
    .gac-form-field {
      &.gac-field-1-2 {
        width: calc((100% - 28px)/2);
      }
      &.gac-field-1-2 + .gac-field-1-2 {
        margin-left: 28px;
      }
      &.gac-field-1-2.gac-currency-select {
        width: 120px;
        margin-left: auto;
      }
      &.gac-field-quantity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 128px;
        margin-right: 64px;
        .gac-form-label {
          width: 100%;
          margin-bottom: 7px;
        }
        input {
          width: 48px;
          height: 48px;
          padding: 0 5px;
          border: 1px solid $border-color;
          color: $dark-text-color;
          font-size: 15px;
          text-align: center;
          border-radius: 2px;
        }
        .gac-decrease-qty,
        .gac-increase-qty {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          cursor: pointer;
          &.gac-qty-inactive {
            cursor: default;
            svg {
              path {
                fill: $grey-color;
              }
            }
          }
          &.gac-increase-qty {
            &.gac-qty-inactive {
              svg {
                path {
                  fill: $grey-color;
                }
              }
            }
          }
        }
      }
      &.gac-field-word-count {
        flex: 1 1 auto;
        .gac-form-label {
          width: 100%;
          margin-bottom: 1px;
        }
        .gac-word-count-wrap {
          display: flex;
          .gac-word-count-wrapper {
            flex: 0 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            border: 1px solid transparent;
            border-radius: 2px;
            &.gac-invalid {
              border-color: $red-color;
            }
          }
          .gac-word-count-item {
            position: relative;
            padding: 10px 10px 11px;
            margin-right: 8px;
            background-clip: padding-box;
            border-radius: 5px;
            z-index: 1;
            cursor: pointer;
            text-align: center;
            &.gac-item-disabled {
              opacity: 0.7;
              cursor: default;
            }
            &.gac-item-active {
              background: $green-color;
            }
            &.gac-item-custom {
              padding: 10px 8px 7px 8px;
              margin-right: 0;
              i {
                margin-bottom: 8px;
              }
              .gac-read-only {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;
                cursor: default;
              }
            }
            &:before {
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              border: 2px solid transparent;
              background-color: $white-color;
              background-clip: padding-box;
              border-radius: 5px;
              content: '';
              z-index: -1;
            }
            i {
              display: block;
              width: 20px;
              height: 24px;
              margin: 0 auto 14px;
              background: url('../../images/sprite.svg') 0 -345px;
              &.gac-icon-400,
              &.gac-icon-500,
              &.gac-icon-150,
              &.gac-icon-120 {
                background-position: 0 -345px;
              }
              &.gac-icon-600,
              &.gac-icon-800,
              &.gac-icon-250,
              &.gac-icon-200 {
                background-position: -20px -345px;
              }
              &.gac-icon-1000,
              &.gac-icon-350,
              &.gac-icon-1200 {
                width: 29px;
                background-position: -40px -345px;
              }
              &.gac-icon-1500,
              &.gac-icon-1600 {
                width: 29px;
                background-position: -70px -345px;
              }
              &.gac-icon-2000,
              &.gac-icon-3000 {
                width: 38px;
                background-position: -100px -345px;
              }
              &.gac-icon-custom {
                width: 29px;
                background-position: -140px -345px;
              }
            }
            span {
              color: $dark-text-color;
              font-size: 15px;
            }
            input {
              width: 70px;
              height: 30px;
              padding: 0;
              line-height: normal;
              border: 1px solid $border-color;
              border-radius: 2px;
              color: $dark-text-color;
              text-align: center;
              &:disabled {
                background-color: $white-color;
              }
            }
          }
          .gac-word-count-item[data-value='600'] + .gac-word-count-item[data-value='800'] {
            i {
              &.gac-icon-800 {
                width: 29px;
                background-position: -40px -345px;
              }
            }
          }
          .gac-word-count-item[data-value='800'] + .gac-word-count-item[data-value='1000'] {
            i {
              &.gac-icon-1000 {
                width: 29px;
                background-position: -70px -345px;
              }
            }
          }
          .gac-word-count-item[data-value='1200'] + .gac-word-count-item[data-value='2000'] {
            i {
              &.gac-icon-2000 {
                width: 29px;
                background-position: -70px -345px;
              }
            }
          }
        }
      }
      &.gac-writer-level {
        .gac-form-field-content {
          flex-wrap: wrap;
          .gac-hint {
            width: 100%;
            margin-top: 9px;
            margin-left: 0;
            .gac-hint-text {
              width: 570px;
              left: -124px;
              transform: none;
              &:before {
                margin-left: 0;
                left: 123px;
              }
            }
          }
        }
      }
      &.gac-field-topic {
        flex: 1 1 auto;
        .gac-form-input {
          input {
            border-radius: 2px 0 0 2px;
          }
        }
      }
      &.gac-field-words {
        flex: 0 0 85px;
        .gac-form-input {
          input {
            border-left: 0;
            border-radius: 0 2px 2px 0;
          }
        }
      }
      &.gac-field-order_on {
        flex: 0 0 25%;
        .gac-input-order-on {
          position: relative;
        }
        .gac-daypicker-wrap {
          position: absolute;
          bottom: 48px;
          left: 0;
          margin: 0;
          background-color: $white-color;
          box-shadow: 0 10px 100px 0 rgba(159, 177, 188, 0.3);
          z-index: 5;
        }
      }
      &.gac-field-written_by {
        flex: 0 0 162px;
        margin-right: 36px;
      }

      .gac-form-field-content {
        display: flex;
        align-items: center;
        &.gac-invalid {
          #planned_publish {
            border-color: $red-color;
          }
          .gac-timepicker {
            input {
              border-color: $red-color;
            }
          }
        }
        .gac-hint {
          position: relative;
          top: 1px;
          margin-left: 10px;
        }
      }
      .gac-form-label {
        display: block;
        line-height: 24px;
        color: $dark-text-color;
        font-size: 14px;
      }
      .gac-form-radio {
        position: relative;
        display: flex;
        &.gac-invalid {
          position: relative;
          &:before,
          &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 1px;
            background-color: $red-color;
            content: "";
            z-index: 1;
          }
          &:after {
            top: auto;
            bottom: 0;
          }
          .gac-radio-item {
            border-color: $red-color;
            &:first-child {
              border-right-color: $border-color;
            }
          }
        }
        &.gac-disabled {
          .gac-radio-item:not(.gac-item-active) {
            border-color: rgba(226,231,235,0.5);
            color: rgba(72,75,88,0.5);
            cursor: default;
          }
        }
        &.gac-radio-job-group {
          margin-bottom: 16px;
          .gac-radio-item {
            width: 33.3333%;
          }
        }
        &.gac-radio-job-group-design {
          margin-bottom: 16px;
          .gac-radio-item {
            width: 50%;
          }
        }
        &.gac-radio-job-group-seo {
          margin-bottom: 16px;
          .gac-radio-item {
            width: 50%;
          }
        }
        &.gac-radio-job-group-writing {
          margin-bottom: 16px;
          .gac-radio-item {
            width: 20%;
          }
        }
        &.gac-radio-job-pay-type {
          .gac-radio-item {
            width: 33.3333%; /*170px; /*50%;*/
          }
        }
        &.gac-form-radio-animation-style {
          .gac-radio-item {
            width: 119px;
          }
        }
        &.gac-radio-job-type-role6 {
          .gac-radio-item {
            width: 33.3333%;
          }
        }
        //&.gac-radio-job-type {
        //  .gac-radio-item {
        //    width: auto;
        //    padding: 0 14px;
        //    &[data-value="proofreading"] {
        //      padding-right: 40px;
        //    }
        //  }
        //}
        .gac-radio-item {
          position: relative;
          width: 170px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white-color;
          border: 1px solid $border-color;
          color: $dark-text-color;
          cursor: pointer;
          user-select: none;
          &.gac-item-active {
            background-color: $white-color;
            &:before {
              position: absolute;
              left: -1px;
              bottom: -1px;
              width: calc(100% + 2px);
              height: 2px;
              border-radius: 2px;
              background: $green-color;
              content: "";
            }
          }
          &.gac-item-disabled {
            color: rgba(72,75,88,0.5);
            cursor: default;
          }
          &:first-child {
            border-radius: 2px 0 0 2px;
          }
          &:last-child {
            border-radius: 0 2px 2px 0;
          }
          &__label {
            position: absolute;
            top: -6px;
            right: 8px;
            width: 24px;
            line-height: 12px;
            background-color: #EDB438;
            color: $white-color;
            font-size: 8px;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 2px;
            text-align: center;
          }
          span:not(.gac-radio-item__label):first-of-type {
            margin-right: 4px;
          }
          i {
            margin-right: 6px;
            &.gac-star-icon {
              position: relative;
              top: -1px;
            }
          }
          .gac-hint {
            position: absolute;
            right: 12px;
            top: 50%;
            margin-top: -7px;
            margin-left: 0;
            .gac-hint-wrap {
              i {
                margin-right: 0;
              }
              .gac-hint-text {
                width: 570px;
                left: -334px;
                top: 26px !important;
                transform: none;
                &:before {
                  bottom: auto;
                  top: -6px;
                  border-top: 0;
                  border-bottom: 6px solid $dark-text-color;
                  margin-left: 0;
                  left: 333px;
                }
              }
            }
          }
          input {
            max-width: 28px;
            padding: 0;
            margin: 0 2px;
            border: 0;
            border-bottom: 1px solid $dark-text-color;
            color: $dark-text-color;
            font-size: 15px;
            text-align: center;
          }
          .gac-plus {
            position: absolute;
            right: 11px;
            top: 15px;
            width: 17px;
            height: 17px;
            margin-right: 0;
            background: url("../../images/sprite.svg") -190px -700px;
            cursor: pointer;
            &.gac-active {
              background-position: -210px -700px;
            }
          }
        }
        .gac-radio-item + .gac-radio-item {
          border-left: 0;
        }
        .gac-radio-item-hint {
          position: absolute;
          top: -40px;
          left: 0;
          line-height: 22px;
          padding: 4px 16px 6px;
          background-color: $dark-text-color;
          color: $white-color;
          font-size: 15px;
          white-space: nowrap;
          border-radius: 4px;
          &:before {
            position: absolute;
            left: 85px;
            bottom: -6px;
            margin-left: -6px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $dark-text-color;
            content: "";
          }
        }
      }
      .gac-form-radio-icon {
        display: flex;
        justify-content: center;
        svg {
          max-width: 90px;
          height: auto;
        }
      }
      .gac-show-full-list {
        margin-left: 14px;
        cursor: pointer;
      }
      .gac-form-input {
        &.gac-input-planned_publish {
          width: 170px;
          input {
            border-radius: 2px 0 0 2px;
            border-right: 0;
            background: url('../../images/calendar.svg') no-repeat center right;
          }
        }
        &.gac-input-written-by,
        &.gac-input-order-on {
          input {
            background: url('../../images/calendar.svg') no-repeat center right;
          }
        }
        input {
          width: 100%;
          height: 48px;
          padding: 0 16px;
          border: 1px solid $border-color;
          background-color: $white-color!important;
          color: $dark-text-color;
          border-radius: 2px;
          &.gac-invalid {
            border-color:$red-color;
          }
          &.gac-input-calendar {
            cursor: pointer;
          }
        }
        .gac-warning {
          line-height: 16px;
          margin-top: 9px;
          font-size: 12px;
        }
      }
      .gac-default-textarea {
        textarea {
          min-width: 100%;
          max-width: 716px;
          min-height: 116px;
          line-height: 23px;
          padding: 12px 16px 10px;
          border: 1px solid $border-color;
          color: $dark-text-color;
          border-radius: 2px;
          vertical-align: top;
          &.gac-invalid {
            border-color: $red-color;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .gac-form-textarea {
        background-color: $white-color;
        &.gac-invalid {
          .redactor-box {
            border-color: $red-color;
          }
        }
        .redactor-box {
          min-width: 100%;
          max-width: 716px;
          line-height: 23px;
          color: $dark-text-color;
          border: 1px solid $border-color;
          border-radius: 2px 2px 0 0;
          .redactor-in {
            padding: 12px 16px 10px;
            a {
              word-break: break-all;
              font-size: 15px;
              color: $dark-text-color;
              &:hover {
                color: $green-color;
                text-decoration: none;
              }
            }
            h1, h2, h3, h4, h5, h6 {
              margin: 0;
              font-size: 15px;
              font-weight: normal;
              font-family: $font;
            }
            div,
            p,
            ul,
            ol,
            table {
              line-height: 24px !important;
              color: $dark-text-color!important;
              font-size: 15px !important;
              font-family: $font;
              font-size-adjust: 0.488;
              font-weight: 400;
              font-style: normal;
            }
            ul{
              list-style: disc!important;
            }
            ol{
              list-style: decimal;
            }
            ul,
            ol {
              padding-left: 2em !important;
            }
            ul ul,
            ol ol,
            ul ol,
            ol ul {
              margin: 2px !important;
              padding: 0 !important;
              padding-left: 2em !important;
              border: none;
            }
            table {
              border-collapse: collapse;
              font-size: 1em !important;
              thead td {
                border-bottom: 2px solid #000 !important;
                font-family: $font;
                font-size-adjust: 0.488;
                font-weight: normal!important;
                font-style: normal;
              }
              th, th * {
                font-weight: bold;
                text-align: left;
              }
              td {
                padding: 5px !important;
                border: 1px solid $border-color;
                vertical-align: top;
              }
            }
          }
        }
        textarea {
          // display: none;
        }
      }
      .gac-field-topic-textarea {
        position: relative;
        .gac-field-topic-rows {
          position: absolute;
          width: 26px;
          height: 100%;
          padding: 12px 0 0 0;
          span {
            display: block;
            line-height: 22px;
            color: $light-text-color;
            text-align: right;
          }
        }
        textarea {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          padding: 11px 100px 0 32px;
          line-height: 22px;
          border: 1px solid $border-color;
          color: $dark-text-color;
          border-radius: 2px;
          vertical-align: top;
          &.gac-invalid {
            border-color: $red-color;
          }
          &.gac-invalid + .gac-topic-textarea-border {
            position: absolute;
            right: 84px;
            width: 1px;
            height: 100%;
            background-color: $red-color;
            z-index: 1;
          }
          &:focus {
            outline: none;
          }
        }
        .gac-wordcount-textarea {
          position: absolute;
          right: 0;
          top: -24px;
          width: 85px;
          .gac-wordcount-textarea-wrap {
            width: 100%;
            padding: 11px 0 0 0;
            border: 1px solid $border-color;
            border-radius: 0 2px 2px 0;
            &.gac-invalid {
              border-color: $red-color;
            }
            input {
              width: 100%;
              height: 22px;
              padding: 0 10px 0 15px;
              color: $dark-text-color;
              font-size: 15px;
              border: 0;
              &:disabled {
                background-color: $white-color;
              }
            }
          }
          //textarea {
          //  min-width: 100%;
          //  padding-left: 15px;
          //  padding-right: 15px;
          //  overflow: hidden;
          //}
        }
        .gac-warning {
          line-height: 16px;
          margin-top: 9px;
          font-size: 12px;
        }
      }
      .gac-form-hint {
        .gac-hint {
          margin-top: 9px;
        }
      }
      .gac-timepicker {
        width: 170px;
        input {
          border-radius: 0 2px 2px 0;
          cursor: pointer;
        }
      }
      .gac-form-checkbox {
        display: flex;
        align-items: center;
        line-height: 16px;
        color: $dark-text-color;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
        input {
          display: none;
        }
        input:checked + span {
          background: url("../../images/sprite.svg") -40px -110px;
        }
        span {
          flex: 0 0 16px;
          height: 16px;
          margin-right: 6px;
          background: url("../../images/sprite.svg") -40px -90px;
        }
      }
    }
    .gac-meta-wordcount {
      margin-top: 9px;
      color: $light-text-color;
      font-size: 12px;
    }
  }
  .gac-form-btns {
    display: flex;
    align-items: center;
    padding-top: 16px;
    & > *:not(.gac-cutoff-time) {
      width: 180px;
    }
    .gac-btn {
      margin-right: 20px;
    }
  }
  .gac-form-title {
    padding-top: 20px;
    color: $dark-text-color;
    font-weight: bold;
    font-size: 20px;
  }
}
.gac-cutoff-time {
  display: flex;
  align-items: center;
  .gac-hint {
    margin-left: 6px;
    &.gac-hint-default .gac-hint-wrap i {
      background-position: -170px -345px;
    }
  }
}
.gac-addons {
  position: relative;
  flex: 1 1 auto;
  padding: 0 0 30px;
  margin-right: 30px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  .gac-addons-head {
    display: flex;
    align-items: flex-start;
    padding: 23px 31px 21px;
    border-bottom: 1px solid $border-color;
    .gac-addons-title {
      flex: 1 1 auto;
      line-height: 26px;
      color: $dark-text-color;
      font-size: 20px;
      font-weight: bold;
      span {
        display: block;
        color: $light-text-color;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .gac-addons-back {
      line-height: 20px;
      padding-top: 3px;
      display: flex;
      align-items: stretch;
      i {
        display: block;
        width: 24px;
        height: 20px;
        margin-left: 8px;
        background: url('../../images/sprite.svg') no-repeat -130px -370px;
        cursor: pointer;
      }
    }
  }
  .gac-addons-content {
    padding: 0 31px;
    .gac-addons-content-title {
      padding: 32px 0 20px 0;
      line-height: 26px;
      font-size: 18px;
      font-weight: 600;
    }
    .gac-addons-item {
      display: flex;
      align-items: stretch;
      line-height: 48px;
      margin-bottom: 8px;
      color: $light-text-color;
      .gac-addons-item-title {
        flex: 1 1 260px;
      }
      .gac-addons-item-desc {
        flex: 1 1 195px;
      }
      .gac-addons-item-price {
        flex: 0 0 98px;
      }
      .gac-addons-item-qty {
        flex: 0 0 115px;
        input {
          width: 48px;
          height: 48px;
          padding: 0 6px;
          border: 1px solid $border-color;
          border-radius: 2px;
          color: $light-text-color;
          text-align: center;
        }
      }
      .gac-addons-item-btn {
        flex: 0 0 48px;
      }
    }
  }
}
.gac-confirm {
  padding: 21px 0 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  margin-right: 30px;
  background-color: $white-color;
  border: 2px dashed $border-color;
  border-radius: 2px;
  .gac-confirm-wrap {
    width: 298px;
    .gac-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 72px;
      color: $dark-text-color;
      font-size: 20px;
      font-weight: bold;
    }
    .gac-cart-projects {
      margin-bottom: 8px;
      .gac-cart-project {
        padding: 8px 16px 6px;
        margin-bottom: 8px;
        border: 1px solid $border-color;
        border-radius: 2px;
        cursor: pointer;
        .gac-cart-project-row {
          display: flex;
          justify-content: space-between;
          line-height: 24px;
          &:nth-child(1) {
            margin-bottom: 1px;
          }
          .gac-cart-project-type {
            flex: 1 1 auto;
            margin-right: 10px;
            color: $dark-text-color;
            font-size: 18px;
            font-weight: 600;
          }
          .gac-cart-project-topic {
            max-width: 230px;
            color: $light-text-color;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
          .gac-cart-project-more {
            position: relative;
            margin-left: 10px;
            i {
              display: block;
              width: 24px;
              height: 24px;
              background: url('../../images/sprite.svg') no-repeat -170px -290px;
              cursor: pointer;
            }
            ul {
              position: absolute;
              right: -17px;
              top: 21px;
              background-color: $white-color;
              border: 1px solid $border-color;
              box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
              z-index: 10;
              li {
                display: block;
                line-height: 40px;
                padding: 0 30px 0 16px;
                background-color: transparent;
                color: $light-text-color;
                transition: color .3s ease, background-color .3s ease;
                white-space: nowrap;
                cursor: pointer;
                &.gac-more-delete {
                  background: $green-color;
                  position: relative;
                  .gac-btn-overlay {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $white-color;
                    z-index: 1;
                  }
                  span {
                    position: relative;
                    z-index: 2;
                  }
                  &.gac-hover {
                    color: $green-color;
                    .gac-btn-overlay {
                      background-color: $border-color;
                    }
                  }
                }
                &:hover {
                  color: $green-color;
                  background-color: $border-color;
                  &.gac-more-delete {
                    .gac-btn-overlay {
                      background-color: $border-color;
                    }
                  }
                }
              }
            }
          }
        }
        &.gac-active {
          background-color: $border-color;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .gac-add-project {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 48px;
      border: 2px dashed $border-color;
      color: $dark-text-color;
      cursor: pointer;
      border-radius: 2px;
      &.gac-green {
        margin-top: 16px;
        background-color: #f6fcfa;
        border: 2px dashed #53e4a0;
      }
      i {
        width: 13px;
        height: 18px;
        margin-right: 13px;
        background: url('../../images/sprite.svg') no-repeat -40px -310px;
      }
    }
  }
  h1 {
    text-align: center;
    line-height: 50px;
    margin-bottom: 18px;
    color: $dark-text-color;
    font-size: 31px;
    font-weight: bold;
  }
  .gac-cart-subtotal {
    padding-top: 12px;
    padding-bottom: 19px;
    margin-bottom: 12px;
    //border-bottom: 2px solid $border-color;
    p {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .gac-cart-subtotal-label {
        color: $light-text-color;
      }
      .gac-cart-subtotal-value {
        color: $dark-text-color;
      }
      &:last-of-type {
        margin-bottom: 5px;
      }
    }
  }
  .gac-cart-balance {
    padding-bottom: 4px;
    margin-bottom: 14px;
    p {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .gac-cart-subtotal-label {
        color: $light-text-color;
      }
      .gac-cart-subtotal-value {
        color: $dark-text-color;
      }
    }
  }
  .gac-cart-with-bundles {
    font-size: 12px;
    color: $yellow-color-dark;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  .gac-cart-subtotal + .gac-cart-total {
    padding-top: 2px;
  }
  .gac-cart-total {
    padding-top: 26px;
    margin-bottom: 10px;
    p {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .gac-cart-total-label,
      .gac-cart-total-value {
        color: $dark-text-color;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .gac-cart-payment-methods {
    padding-top: 13px;
    margin-bottom: 27px;
  }
  .gac-cart-confirm-order {
    display: block;
    width: 100%;
    margin: 0 0 18px;
    &.gac-disabled {
      margin-bottom: 10px;
    }
    &.gac-stripe-method {
      margin-top: 23px;
    }
  }
  .gac-confirm-error-msg {
    line-height: 18px;
    margin-bottom: 18px;
    font-size: 12px;
  }
  .paypal-button-container:empty + .gac-cart-money-back {
    padding-top: 41px;
    i {
      top: 54px;
    }
  }
  /* PayPal */
  .paypal-button-container {
    margin-bottom: 16px;
    &:empty {
      margin-bottom: 0;
    }
  }
  .gac-cart-money-back {
    position: relative;
    padding: 11px 0 11px 68px;
    i {
      position: absolute;
      top: 24px;
      left: 31px;
      width: 18px;
      height: 22px;
      background: url('../../images/sprite.svg') no-repeat -150px -290px;
    }
    p {
      line-height: 23px;
      color: $light-text-color;
      font-size: 12px;
    }
  }
}
.gac-confirm-mobile-btn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 15px 24px;
  background-color: $bg-color;
  z-index: 100;
  .gac-payment-confirm-page & {
    .gac-btn {
      width: 100%;
      font-size: 20px;
      font-weight: normal;
      border-radius: 4px;
      &:before {
        background-image: none;
        background-color: $green-color;
        border-radius: 4px;
      }
    }
  }
  .gac-btn {
    width: 100%;
  }
}
.gac-order-projects-wrap {
  position: relative;
  flex: 1 1 auto;
  max-width: calc(100% - 360px);
  padding: 0;
  margin-right: 30px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  &.gac-order-empty {
    display: flex;
    flex-direction: column;

  }
  .gac-empty-state {
    border: 0;
    padding-bottom: 24px;
    min-height: 300px;
  }
}
.gac-order-projects-list {
  .gac-projects-list {
    position: relative;
    z-index: 1;
  }
  .gac-projects-list-head {
    display: flex;
    align-items: center;
    padding: 0 31px;
    .gac-projects-list-title {
      line-height: 73px;
      font-size: 20px;
      font-weight: bold;
      flex: 1 1 auto;
    }
    .gac-back-btn {
      display: flex;
      align-items: center;
      line-height: 24px;
      color: $light-text-color;
      cursor: pointer;
      user-select: none;
      i {
        position: relative;
        top: 1px;
        width: 6px;
        height: 10px;
        margin-right: 10px;
        background: url('../../images/sprite.svg') no-repeat -154px -370px;
      }
    }
  }
  .gac-search {
    padding: 0 31px;
    margin-bottom: 24px;
    .gac-filters-btn {
      margin-left: 16px;
    }
  }
  .gac-projects-list {
    .gac-project-item {
      position: relative;
      display: flex;
      align-items: stretch;
      min-height: 80px;
      padding: 0 31px 0 46px;
      border-top: 1px solid $border-color;
      transition: background-color .3s ease;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }
      &.gac-project-item-draft {
        cursor: default;
      }
      .gac-btn-rounded.gac-active i svg path {
        fill: $white-color!important;
      }
      &:not(.gac-project-item-draft):hover {
        background-color: $border-color;
        //background-color: $green-color;
        .gac-project-item-btn {
          .gac-btn-rounded {
            &:before {
              border-color: $white-color;
            }
          }
        }
      }
      .gac-project-type {
        position: absolute;
        left: 16px;
        top: 32px;
      }
      .gac-project-item-topic {
        flex: 1 1 auto;
        margin-right: 20px;
        overflow: hidden;
        .gac-project-item-title, .gac-project-item-title a {
          line-height: 22px;
          margin: 18px 0 6px 0;
          font-size: 18px;
          font-weight: 600;
          text-decoration: none;
          &:hover {
            color: $dark-text-color;
          }
        }

      }
      .gac-project-item-team {
        display: flex;
        flex: 0 0 138px;
        align-items: center;
        .gac-writer-avatar {
          flex: 0 0 36px;
          height: 36px;
          border: 2px solid $white-color;
          margin-right: 7px;
          background: url('../../images/sprite.svg') 0 -250px #e9eff3;
          border-radius: 100%;
          transition: background-color .3s ease;
        }
        .gac-writer-name {
          line-height: 20px;
          flex: 1 1 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .gac-project-item-status {
        display: flex;
        flex: 0 0 98px;
        flex-direction: column;
        .gac-status {
          margin: 18px 0 5px 0;
        }
        .gac-date {
          line-height: 20px;
          color: $light-text-color;
          font-style: italic;
        }
      }
      .gac-project-item-btn {
        display: flex;
        flex: 0 0 98px;
        align-items: center;
        justify-content: flex-end;
        &.gac-type-none {
          flex: 0 0 110px;
        }
      }
      p {
        margin: 0;
        color: $light-text-color;
        i {
          margin-left: 19px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .gac-status {
    display: flex;
    align-items: center;
    line-height: 20px;
    text-transform: capitalize;
    i {
      display: block;
      width: 10px;
      height: 10px;
      margin-right: 8px;
      border-radius: 100%;
      &.gac-auto,
      &.gac-approved,
      &.gac-matching {
        background-color: $green-color;
      }
      &.gac-editing, &.gac-writing, &.gac-revision {
        background-color: $blue-color;
      }
      &.gac-published {
        background-color: $granite-gray-color;
      }
      &.gac-draft {
        background-color: $grey-color;
      }
      &.gac-approval, &.gac-pitching {
        background-color: $red-color;
      }
      &.gac-cancelled {
        background-color: $border-color;
      }
    }
  }
  .gac-show-more {
    width: 100%;
    line-height: 53px;
    border-top: 1px solid $border-color;
    text-align: center;
    cursor: pointer;
    color: $light-text-color;
  }
}

.gac-simple-word-count {
  display: flex;
  align-items: center;
  svg {
    margin-right: 6px;
  }
  input {
    width: 70px;
    height: 30px;
    padding: 0;
    margin-right: 6px;
    line-height: normal;
    border: 1px solid $border-color;
    border-radius: 2px;
    color: #1E1E1E;
    text-align: center;
    &.gac-invalid {
      border-color: $red-color;
    }
  }
}

.gac-timepicker {
  position: relative;
  input {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: 1px solid $border-color;
    color: $dark-text-color;
    background: url('../../images/clock.svg') $white-color no-repeat center right;
    //cursor: pointer;
  }
  .gac-timepicker-panel {
    display: flex;
    position: absolute;
    top: 47px;
    left: 0;
    width: 100%;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 0 0 2px 2px;
    z-index: 10000;
    .gac-timepicker-panel-select {
      overflow: auto;
      flex: 1 1 33.3333%;
      &:first-child {
        border-right: 1px solid $border-color;
        li {
          &.gac-active,
          &:hover {
            background: linear-gradient(to right, #53e4a0 0%,#51e2ad 100%);
          }
        }
      }
      &:nth-child(2) {
        li {
          &.gac-active,
          &:hover {
            background: linear-gradient(to right, #51e2ad 0%,#4fdfbb 100%);
          }
        }
      }
      &:last-child {
        border-left: 1px solid $border-color;
        li {
          &.gac-active,
          &:hover {
            background: linear-gradient(to right, #4fdfbb 0%,#4cdcc8 100%);
          }
        }
      }
      li {
        display: block;
        line-height: 48px;
        text-align: center;
        //transition: color .3s ease, background-color .3s ease;
        cursor: pointer;
        &.gac-active,
        &:hover {
          color: $white-color;

        }
      }
    }
  }
}
.gac-more-details {
  display: flex;
  align-items: center;
  margin: 24px 0 0 24px;
  color: $light-text-color;
  cursor: pointer;
  &.gac-show-less {
    span {
      i {
        background-position: -85px -370px;
      }
    }
  }
  span {
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 11px;
    border-radius: 100%;
    background: $green-color;
    background-clip: padding-box;
    i {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      background: url('../../images/sprite.svg') -65px -370px;

    }
  }
}
.gac-invite-writers {
  display: flex;
  padding-top: 4px;
  .gac-writers-all {
    position: relative;
    width: 49px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #8C9EA9;
    font-size: 14px;
    cursor: pointer;
    border-radius: 16px;
    &.gac-active {
      background: $green-color;
      color: $white-color;
      cursor: default;
      &:before {
        border-color: transparent;
      }
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $border-color;
      border-radius: 16px;
      content: "";
    }
  }
  .gac-writers-label {
    width: 55px;
    line-height: 30px;
    color: $dark-text-color;
    text-align: center;
  }
  .gac-writers-list {
    position: relative;
    display: flex;
    .gac-writer-item {
      position: relative;
      width: 32px;
      height: 32px;
      line-height: 30px;
      margin-left: -4px;
      background-color: $border-color;
      border-radius: 100%;
      cursor: pointer;
      text-align: center;
      font-size: 12px;
      color: #8C9EA9;
      text-transform: uppercase;
      &.gac-active {
        background: $green-color;
        border: 1px solid transparent;
        color: $white-color;
        &:before {
          left: -3px;
          top: -3px;
          right: -3px;
          bottom: -3px;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:before {
        position: absolute;
        left: -2px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        border: 2px solid $white-color;
        border-radius: 100%;
        content: '';
      }
      &:hover {
        .gac-writer-name {
          opacity: 1;
          z-index: 1;
          visibility: visible;

        }
      }
      img {
        border-radius: 100%;
      }
      .gac-writer-name {
        position: absolute;
        top: -37px;
        left: 50%;
        line-height: 18px;
        padding: 4px 10px 6px;
        background-color: $dark-text-color;
        color: $white-color;
        font-size: 15px;
        border-radius: 4px;
        text-transform: capitalize;
        transform: translateX(-50%);
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        &:before {
          position: absolute;
          left: 50%;
          bottom: -5px;
          margin-left: -7px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 5px solid $dark-text-color;
          content: "";
        }
      }
    }
    .gac-no-writers {
      position: absolute;
      top: -40px;
      left: 50%;
      line-height: 22px;
      padding: 4px 16px 6px;
      background-color: $dark-text-color;
      color: $white-color;
      font-size: 15px;
      white-space: nowrap;
      border-radius: 4px;
      transform: translateX(-50%);
      z-index: 20;
      &:before {
        position: absolute;
        left: 50%;
        bottom: -6px;
        margin-left: -6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $dark-text-color;
        content: "";
      }
    }
    .gac-writers-rest {
      position: relative;
      margin-left: 6px;
      margin-right: 12px;
      line-height: 32px;
      &:empty {
        margin-left: 0;
      }
      .gac-writers-rest-list {
        display: none;
        position: absolute;
        left: 50%;
        line-height: 22px;
        padding: 4px 10px 6px;

        background-color: $dark-text-color;
        color: $white-color;
        font-size: 15px;
        white-space: nowrap;
        border-radius: 4px;
        transform: translateX(-50%);
        z-index: 20;
        &.bottom {
          &:before {
            top: -6px;
            border-top: 0!important;
            border-bottom: 6px solid $dark-text-color;
          }
        }
        &:before {
          position: absolute;
          left: 50%;
          bottom: -6px;
          margin-left: -6px;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid $dark-text-color;
          content: "";
        }
      }
      &:hover {
        .gac-writers-rest-list {
          display: block;
        }
      }
    }
  }
  .gac-writers-plus {
    position: relative;
    width: 32px;
    height: 32px;
    line-height: 30px;
    margin-left: -4px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 100%;
    color: $green-color;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &:before {
      position: absolute;
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border: 2px solid $white-color;
      border-radius: 100%;
      content: '';
    }
  }
}

.gac-search {
  display: flex;
  transition: margin-bottom .2s ease;
  &.gac-filters-on {
    margin-bottom: 16px;
  }
  .gac-search-input-wrap {
    position: relative;
    flex: 1 1 auto;
    .gac-search-input {
      width: 100%;
      height: 48px;
      line-height: normal;
      padding: 0 54px 0 15px;
      border: 1px solid $border-color;
      border-radius: 2px;
      color: $dark-text-color;
      font-size: 15px;
      box-shadow: none!important;
      background-clip: padding-box;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $light-text-color;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $light-text-color;
        opacity: 1;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $light-text-color;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $light-text-color;
        opacity: 1;
      }
    }
    .gac-search__total {
      position: absolute;
      right: 56px;
      top: 12px;
      line-height: 22px;
      color: $light-text-color;
      font-size: 15px;
    }
    .gac-search__btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 48px;
      height: 48px;
      background: url('../../images/sprite.svg') no-repeat -115px 0;
      cursor: pointer;
    }
  }
}
.gac-order-filters {
  display: flex;
  padding: 0 31px;
  .gac-filter-item {
    flex: 0 1 228px;
    max-width: calc((100% - 32px)/3);
    margin-right: 16px;
    margin-bottom: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.gac-attachment {
  border: 1px solid $border-color;
  border-top: 0;
  background-color: $white-color;
  border-radius: 0 0 2px 2px;
  &.gac-invalid {
    position: relative;
    border-color: $red-color;
    &:before {
      position: absolute;
      left: -1px;
      top: -1px;
      width: calc(100% + 2px);
      height: 1px;
      background-color: $red-color;
      content: "";
    }
  }
  &.gac-attachment-motion {
    border: 0;
    .gac-dropzone {
      padding: 10px 0 0 0;
      align-items: stretch;
      cursor: default;
      span {
        margin-right: 0;
        cursor: pointer;
      }
      p {
        display: flex;
        flex: 0 0 auto;
        padding-left: 16px;
        align-items: center;
        cursor: pointer;
      }
    }
    .gac-attachment-files {
      padding: 8px 0 0;
    }
  }
  .gac-attachment-files {
    display: grid;
    grid-template-columns: calc((100% - 8px) / 2) calc((100% - 8px) / 2);
    grid-gap: 8px;
    flex-wrap: wrap;
    padding: 8px 8px 0;
    overflow: hidden;
    .gac-files-item {
      display: flex;
      align-items: center;
      height: 24px;
      padding: 0 0 0 7px;
      margin: 0;
      background-color: #f3f7f8;
      border-radius: 4px;
      overflow: hidden;
      .gac-files-item-type {
        flex: 0 0 auto;
        width: 12px;
        height: 16px;
        margin-right: 9px;
        background: url('../../images/sprite.svg') -110px -440px;
        &.jpg,
        &.png,
        &.psd {
          background-position: -122px -440px;
        }
      }
      .gac-files-item-name {
        flex: 1 1 auto;
        line-height: 24px;
        padding-right: 10px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        &:hover {
          color: $dark-text-color;
        }
      }
      .gac-align-right {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        margin-left: auto;
      }
      .gac-files-item-progress-wrap {
        display: flex;
        align-items: center;
      }
      .gac-files-item-progress-bar {
        position: relative;
        width: 41px;
        height: 6px;
        background-color: $white-color;
        border-radius: 3px;
        span {
          position: absolute;
          left: 0;
          top: 0;
          height: 6px;
          background: $green-color;
          border-radius: 3px;
        }
      }
      .gac-files-item-percentage {
        margin: 0 0 0 6px;
        font-size: 10px;
      }
      .gac-files-item-size {
        color: #8C9EA9;
        font-size: 9px;
        white-space: nowrap;
      }
      .gac-files-item-remove {
        width: 24px;
        height: 24px;
        margin-left: 2px;
        background: url('../../images/sprite.svg') -160px -415px;
        cursor: pointer;
      }
    }
  }
  .gac-dropzone-wrap {
    display: flex;
    align-items: center;
  }
  .gac-dropzone {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 15px;
    margin-right: 30px;
    color: $light-text-color;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      width: 40px;
      height: 40px;
      margin-right: 16px;
      background: $green-color;
      border-radius: 100%;
      i {
        width: 16px;
        height: 18px;
        background: url('../../images/sprite.svg') -90px -430px;
      }
    }
    span ~ p {
      line-height: 20px;
      flex: 1 1 auto;
    }
  }
}
.gac-brief-example {
  display: flex;
  align-items: center;
  color: #6f6f7a;
  font-size: 15px;
  text-decoration: none;
  &:hover, &:active, &:focus {
    color: #6f6f7a;
  }
  span {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 20px;
    background: url('../../images/sprite.svg') -60px -735px $green-color;
  }
}

.gac-form-field .gac-field-topic-textarea + .gac-error,
.gac-form-field .gac-form-input input + .gac-error,
.gac-form-field + .gac-error,
.gac-attachment + .gac-error {
  line-height: 16px;
  margin-top: 9px;
  font-size: 12px;
}
.gac-custom-select {
  &.gac-invalid {
    .gac-select__control {
      border-color: $red-color!important;
    }
  }
  .gac-select__control {
    border-color: $border-color;
    border-radius: 2px;
    cursor: pointer;
    &.gac-select__control--is-disabled {
      background-color: white;
      border-color: $disabled-color;
      color: #cfd0d6;
    }
    &.gac-select__control--is-focused {
      box-shadow: none;
    }
    &:hover {
      border-color: $border-color;
    }
    .gac-select__value-container {
      padding: 9px 17px 9px;
      .gac-select__placeholder {
        margin: 0;
        color: $dark-text-color;
      }
      .gac-select__placeholder + div {
        margin-left: 0;
        margin-right: 0;
      }
      .gac-select__multi-value {
        position: relative;
        height: 24px;
        margin: 0 12px 0 0;
        background-color: $white-color;
        border: 1px solid $border-color;
        border-radius: 12px;
        .gac-select__multi-value__label {
          line-height: 22px;
          padding: 0 6px;
          color: $dark-text-color;
          font-size: 12px;
        }
        .gac-select__multi-value__remove {
          position: absolute;
          width: 14px;
          height: 14px;
          right: -7px;
          top: -7px;
          border-radius: 100%;
          cursor: pointer;
          background: url('../../images/sprite.svg') -135px -435px #afaeaf;
          svg {
            display: none;
          }
        }
      }
    }
  }
  .gac-select__indicators {
    padding-right: 15px;
    .gac-select__clear-indicator,
    .gac-select__indicator-separator {
      display: none;
    }
    .gac-select__dropdown-indicator {
      position: relative;
      width: 24px;
      height: 24px;
      padding: 0;
      cursor: pointer;
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 6px;
        margin-top: -3px;
        margin-left: -5px;
        background: url('../../images/sprite.svg') no-repeat 0 0;
        z-index: 2;
        content: '';
      }
      svg {
        display: none;
      }
    }
  }
  .gac-select__menu {
    border: 1px solid $border-color;
    background-color: $white-color;
    box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
    border-radius: 2px;
    z-index: 100;
    .gac-select__menu-list {
      padding: 8px 0;
    }
    .gac-select__option {
      line-height: 32px;
      padding: 0 16px;
      &.gac-select__option--is-selected {
        background: $green-color;
        color: $white-color;
      }
      &.gac-select__option--is-focused {
        background-color: $border-color;
      }
    }
    .gac-select__menu-notice {
      &.gac-select__menu-notice--no-options {
        text-align: left;
        line-height: 32px;
        padding: 0 16px;
        color: $dark-text-color;
      }
    }
  }
}

.gac-confirm,
.gac-modal,
.gac-modal-subscription,
.gac-sub-dropin-wrap {
  /* Payments */
  [data-braintree-id="upper-container"] {
    margin-bottom: 22px;
    &:before {
      background-color: transparent;
    }
  }
  [data-braintree-id="card-view-icons"] {
    display: none;
  }
  [data-braintree-id="save-card-field-group"] .braintree-form__label {
    color: $light-text-color;
  }
  [data-braintree-id="toggle"] {
    margin-top: -32px;
    margin-bottom: 27px;
  }
  [data-braintree-id="methods"] {
    margin-bottom: 27px;
  }
  [data-braintree-id="delete-confirmation"] {
    margin-bottom: 5px;
  }
  [data-braintree-id="sheet-container"] {
    margin-bottom: 5px;
  }
  [data-braintree-id="card"] {
    border-color: $border-color;
    border-radius: 2px;
  }
  [data-braintree-id="card-sheet-header"] {
    border-color: $border-color;
  }
  [data-braintree-id="number-field-group"],
  [data-braintree-id="cvv-field-group"],
  [data-braintree-id="expiration-date-field-group"] {
    .braintree-form__label {
      font-size: 14px;
      color: $dark-text-color;
    }
    &.braintree-form__field-group--has-error {
      .braintree-form__field {
        .braintree-form__hosted-field {
          border-color: $red-color!important;
        }
      }
    }
    .braintree-form__field {
      .braintree-form__hosted-field {
        padding: 0 16px;
        border-color: $border-color!important;
        border-radius: 2px;
        &.braintree-form__field--valid {
          border-color: $border-color!important;
        }
      }
    }

  }
  [data-braintree-id="methods-container"] {
    .braintree-method {

      border: 1px solid $border-color;
      border-radius: 2px;
    }
  }
  .braintree-sheet__content--form {
    .braintree-form__field-group {
      .braintree-form__field.braintree-form__checkbox input {
        margin-top: 4px;
      }
    }
  }
  .braintree-dropin {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
    [data-braintree-id="methods-label"] {
      width: 70%;
      font-size: 14px;
    }
    [data-braintree-id="methods-edit"] {
      width: auto;
      margin-left: auto;
      font-size: 14px;
    }
    .braintree-placeholder {
      display: none;
    }
  }
  .braintree-show-methods [data-braintree-id='methods-label'],
  .braintree-show-methods [data-braintree-id='methods-edit'],
  .braintree-show-options [data-braintree-id='choose-a-way-to-pay'] {
    position: static;
  }
}
.gac-modal-subscription,
.gac-payment-confirm-page {
  .gac-stripe .gac-stripe-form__checkbox,
  [data-braintree-id="save-card-field-group"] {
    display: none;
  }
}
.gac-card-dropin,
.gac-confirm {
  [data-braintree-id="methods-edit"] {
    display: none;
  }
}
.gac-sub-dropin-wrap {
  .braintree-sheet__header {
    padding: 16px 16px 0 16px;
    border-bottom: 0;
    .braintree-sheet__header-label {
      padding-bottom: 15px;
    }
    .braintree-sheet__logo--header {
      width: 32px;
      height: 24px;
      background: url("../../images/sprite.svg") 0 -705px;
      svg {
        display: none;
      }
    }
    .braintree-sheet__text {
      margin-left: 16px;
      color: $dark-text-color;
      font-family: $font;
      font-weight: bold;
    }
  }
  .braintree-sheet__content--form {
    padding-top: 0;
  }
  [data-braintree-id="card"] {
    border-color: #F7C300;
    border-radius: 4px;
  }
  [data-braintree-id="number-field-group"],
  [data-braintree-id="cvv-field-group"],
  [data-braintree-id="expiration-date-field-group"] {
    .braintree-form__label {
      font-size: 15px;
    }
    .braintree-form__field {
      .braintree-form__hosted-field {
        height: 40px;
        border-radius: 4px;
      }
    }

  }
}

/* Dimensions */
.gac-dimensions,
.gac-format-options {
  display: flex;
  flex-wrap: wrap;
  &__item {
    position: relative;
    line-height: 20px;
    padding: 15px;
    margin: 0 8px 8px 0;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    &.gac-active {
      border-color: $green-color;
    }
    &.gac-disabled {
      opacity: 0.6;
      cursor: default;
    }
    &.gac-multiple {
      padding-right: 92px;
    }
    input {
      width: 54px;
      height: 20px;
      border: 1px solid $border-color;
      background-color: $white-color;
      border-radius: 2px;
      font-size: 12px;
      &[data-prop="custom_horizontal"] {
        margin-right: 6px;
      }
      &[data-prop="custom_vertical"] {
        margin-left: 6px;
        margin-right: 6px;
      }
    }
    .gac-select {
      height: 20px;
      .gac-select-value {
        height: 20px;
        min-width: 54px;
        line-height: 17px;
        padding: 0 30px 0 6px;
        font-size: 13px;
        color: $dark-text-color;
      }
      .gac-select-menu {
        top: 22px!important;
        min-width: 86px;
      }
      &:after {
        right: 12px;
      }
    }
  }
  &__p {
    display: flex;
    align-items: center;
    line-height: 22px;
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 13px;
    bottom: 7px;
    width: 78px;
    height: 32px;
    margin-top: -22px;
    input {
      height: 32px;
      width: 32px;
      padding: 0;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      color: #1e1e1e;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
    }
    .gac-increase-qty,
    .gac-decrease-qty {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 19px;
      height: 19px;
      cursor: pointer;
    }
  }
  &__border-left,
  &__border-right,
  &__border-top,
  &__border-bottom {
    position: absolute;
    background-color: $green-color;
  }
  &__border-left,
  &__border-right {
    top: 0;
    width: 1px;
    height: 100%;
  }
  &__border-top,
  &__border-bottom {
    left: 0;
    width: 100%;
    height: 1px;
  }
}
.gac-format-options {
  justify-content: space-between;
  &__item {
    flex: 0 0 50%;
    max-width: calc(50% - 8px);
    align-items: flex-start;
    margin-right: 0;
    margin-bottom: 16px;
    padding-bottom: 40px;
  }
  &__p {
    line-height: 20px;
    font-size: 13px;
  }
  &__title {
    line-height: 22px;
    font-weight: 600;
  }
  &__price {
    position: absolute;
    left: 15px;
    bottom: 10px;
    line-height: 22px;
    font-weight: bold;
  }
  &__custom {
    display: flex;
    align-items: center;
    position: absolute;
    left: 15px;
    bottom: 10px;
    line-height: 22px;
    font-weight: bold;
    input {
      margin-left: 4px;
      padding: 0 4px;
      font-weight: 600;
    }
  }
}
/* File types */
.gac-file-types {
  display: flex;
  flex-wrap: wrap;
  &__item {
    position: relative;
    line-height: 30px;
    min-width: 56px;
    padding: 0 14px;
    margin: 0 8px 8px 0;
    border: 1px solid $border-color;
    background-color: $white-color;
    color: $dark-text-color;
    font-size: 14px;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
  &__active {
    background: $green-color;
    border-color: $green-color;
    color: $white-color;
    &[data-value="fig"],
    &[data-value="ai"],
    &[data-value="psd"],
    &[data-value="indd"],
    &[data-value="ppt"],
    &[data-value="key"],
    &[data-value="gslides"] {
      background: $white-color;
      border-color: $border-color;
      color: $dark-text-color;
      &:before {
        position: absolute;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 1px;
        height: 2px;
        background-color: $green-color;
        content: "";
      }
    }
  }
}

/* Design images */
.gac-design-workflow,
.gac-design-images {
  ul {
    display: flex;
    flex-wrap: wrap;
    &.gac-invalid {
      li {
        border-color: $red-color;
      }
    }
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 72px;
      border: 1px solid $border-color;
      background-color: $white-color;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 0 15px;
      border-radius: 2px;
      cursor: pointer;
      user-select: none;
      &.gac-active:before {
        position: absolute;
        width: calc(100% + 2px);
        height: 2px;
        left: -1px;
        bottom: -1px;
        border-radius: 1px;
        background-color: $green-color;
        content: "";
      }
      &:nth-child(1) {
        padding-top: 15px;
        i {
          margin-bottom: 7px;
        }
      }
      &:nth-child(2) {
        padding-top: 12px;
        i {
          margin-bottom: 4px;
        }
      }
      &:nth-child(3) {
        padding-top: 12px;
        i {
          margin-bottom: 4px;
        }
      }
      &:nth-child(4) {
        margin-right: 0;
        padding-top: 15px;
        i {
          margin-bottom: 7px;
        }
      }
      i {
        display: flex;
      }
      p {
        line-height: 22px;
        color: $dark-text-color;
      }
    }
  }
}
.gac-design-workflow {
  ul li {
    min-width: 130px;
  }
}
.gac-workflow-row {
  .gac-hint .gac-hint-wrap .gac-hint-text {
    left: 73px;
    &:before {
      left: 135px;
    }
  }
}
.gac-used-services-row {
  .gac-hint {
    margin-top: 9px;
    .gac-hint-wrap .gac-hint-text {
      //left: 73px;
      //&:before {
      //  left: 135px;
      //}
    }
  }
}
.gac-review-frequency-row {
  .gac-hint {
    margin-top: 9px;
    .gac-hint-wrap .gac-hint-text {
      left: 129px;
      &:before {
        left: 79px;
      }
    }
  }
}

.gac-design-files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  li {
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    img {
      width: auto;
      height: auto;
      max-height: 60px;
      border-radius: 2px;
    }
    span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  .gac-add-new-file {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    height: 60px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    cursor: pointer;
  }
}
.gac-project-details {
  .gac-design-files li img {
    cursor: pointer;
  }
}

/* Brand profiles */
.gac-brand-profiles {
  ul {
    display: flex;
    flex-wrap: wrap;
    &.gac-invalid {
      li {
        border-color: $red-color;
      }
    }
    li {
      position: relative;
      display: flex;
      flex: 0 0 160px;
      flex-direction: column;
      align-items: center;
      height: 72px;
      border: 1px solid $border-color;
      background-color: $white-color;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 12px 13px 0;
      border-radius: 2px;
      cursor: pointer;
      &:hover {
        .gac-edit {
          opacity: 1;
        }
      }
      &.gac-active:before {
        position: absolute;
        width: calc(100% + 2px);
        height: 2px;
        left: -1px;
        bottom: -1px;
        border-radius: 1px;
        background-color: $green-color;
        content: "";
      }
      i {
        display: flex;
        width: 24px;
        height: 24px;
        margin-bottom: 3px;
        border-radius: 100%;
      }
      p {
        line-height: 22px;
        max-width: 132px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $dark-text-color;
      }
      .gac-edit {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 23px;
        height: 23px;
        border: 5px solid $white-color;
        background: url("../../images/sprite.svg") -230px -580px;
        cursor: pointer;
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
    }
  }
}

/* Design plans */
.gac-design-services {
  padding-top: 20px;
}
.gac-design-cta {
  margin-bottom: 70px;
  &__img {
    margin-bottom: 20px;
  }
  h3, p {
    line-height: 36px;
    margin: 0;
    color: $dark-text-color;
    font-size: 24px;
    font-weight: bold;
  }
  p {
    margin-top: -5px;
    margin-bottom: 20px;
    color: #8E8E8E;
  }
  .gac-btn {
    font-size: 16px;
    &:before {
      background-color: $green-color;
      background-image: none;
    }
  }

}
.gac-design-desc {
  margin-bottom: 70px;
  &__title {
    line-height: 26px;
    margin: 0 0 24px;
    color: $dark-text-color;
    font-size: 20px;
    font-weight: bold;
  }
  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    .gac-design-desc-item {
      &:nth-child(3) {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
/*        .gac-design-desc-item__wrap {
          min-height: 402px;
        }*/
      }
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 276px;
        padding: 32px 15px 18px;
        border: 1px solid $border-color;
        border-radius: 2px;
        text-align: center;
      }
      &__img {
        display: flex;
        margin-bottom: 25px;
      }
      h3 {
        line-height: 26px;
        margin: 0 0 26px;
        color: $dark-text-color;
        font-size: 18px;
      }
      p {
        line-height: 26px;
        margin-bottom: 26px;
        color: $dark-text-color;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.gac-design-list {
  margin-bottom: 66px;
  &__title {
    line-height: 26px;
    margin: 0 0 16px;
    color: $dark-text-color;
    font-size: 20px;
    font-weight: bold;
  }
  ul {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 54px;
    li {
      position: relative;
      line-height: 30px;
      padding-left: 11px;
      &:before {
        position: absolute;
        left: 0;
        top: 13px;
        width: 5px;
        height: 5px;
        background-color: $green-color;
        border-radius: 100%;
        content: "";
      }
    }
  }
}
.gac-design-demo {
  position: relative;
  padding-left: 88px;
  i {
    display: flex;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -22px;
  }
  span, a {
    line-height: 26px;
    color: $dark-text-color;
    font-size: 20px;
    font-weight: bold;
  }
  a {
    line-height: 18px;
    display: inline-block;
    border-bottom: 1px solid $dark-text-color;
    text-decoration: none;
    &:hover {
      border-color: transparent;
    }
  }
}

/* Payment confirm page */
.gac-login-page,
.gac-body-payment-confirm,
.gac-body-company-new,
.gac-body-plans-page {
  background: url("../../images/confirm-payment-bg-2.png") -160px 0px repeat #ffffff;
}
.gac-payment-confirm-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    line-height: 28px;
    padding: 40px 20px;
    background-color: $white-color;
    color: $dark-text-color;
    font-size: 24px;
    font-weight: bold;
    span {
      color: $green-color;
    }
  }
}
.gac-payment-confirm-wrap {
  display: flex;
  width: 556px;
  padding: 20px;
  background-color: $white-color;
  &.motion {
    width: 588px;
    .gac-payment-confirm-wrap__item {
      flex: 0 0 260px;
    }
    .gac-payment-confirm-item-wrap__head i {
      top: -34px;
      right: 2px;
      margin-top: 0;
      transform: none;
    }
  }
  &__item {
    flex: 0 0 228px;
    margin-right: 12px;
    .gac-back {
      display: flex;
      justify-content: center;
      padding-top: 9px;
      a {
        line-height: 21px;
        font-size: 14px;
        color: $light-text-color;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &__title {
    line-height: 25px;
    margin-bottom: 9px;
    color: $dark-text-color;
    font-size: 17px;
  }
  &__method {
    flex: 0 0 264px;
    margin-left: 12px;
    &.prepay .gac-btn,
    &.bundle .gac-btn,
    .gac-confirm-writing-btn,
    .gac-stripe .gac-btn {
      width: 100%;
      height: 48px;
      line-height: 48px;
      margin-top: 0!important;
      font-size: 20px;
      font-weight: normal;
      border-radius: 4px;
      &:before {
        background-image: none;
        background-color: $green-color;
        border-radius: 4px;
      }
    }
  }
}
.gac-payment-confirm-item-wrap {
  padding: 16px 0 18px;
  border: 1px solid $border-color;
  border-radius: 4px;
  &__head {
    position: relative;
    line-height: 32px;
    padding: 0 15px;
    color: $dark-text-color;
    font-size: 26px;
    font-weight: bold;
    transition: all .3s ease-in-out;
    .gac-total {
      margin-top: -6px;
      color: $light-text-color;
    }
    p {
      display: block;
      color: $green-color;
      span {
        color: $dark-text-color;
        font-size: 18px;
        font-weight: normal;
      }
    }
    i {
      display: flex;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -34px;
      transform: scale(0.8);
    }
  }
  &__content {
    padding: 12px 15px 0;
    strong {
      display: block;
      line-height: 24px;
      color: $dark-text-color;
    }
    ul {
      li {
        position: relative;
        line-height: 24px;
        color: $dark-text-color;
        i {

        }
        .gac-free-ideas {
          color: $green-color;
          font-weight: bold;
          text-transform: uppercase;
        }
        .gac-start-date {
          position: relative;
          font-weight: bold;
          cursor: pointer;
          text-decoration: underline;
          i {
            position: absolute;
            right: -23px;
            top: 5px;
            width: 16px;
            height: 12px;
            background: url("../../images/sprite.svg") -190px -680px;
          }
        }
        .gac-daypicker-wrap {
          position: absolute;
          top: 24px;
          left: 0;
          z-index: 7;
          margin: 0;
          background-color: $white-color;
          box-shadow: 0 10px 40px 0 rgba(159, 177, 188, 0.3);
        }
      }
    }

  }
  .gac-bundle-item-v2 {
    padding: 8px 0 7px;
  }
  .gac-prepay-item {
    padding-bottom: 7px;
    .gac-prepay-wrap__icon {
      position: static;
      padding: 22px 0 28px;
      transform: translate(0, 0);
    }
    &__title {
      line-height: 36px;
      margin-bottom: 7px;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }
    &__amount {
      line-height: 42px;
      color: $green-color;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
    }
  }
}

/* Success page */
.gac-success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__img {
    padding: 36px;
    background-color: $white-color;
  }

  h3 {
    line-height: 34px;
    margin: 0;
    padding: 0 0 43px;
    color: $dark-text-color;
    font-size: 28px;
    font-weight: 600;
    background: url("../../images/line.svg") center 36px no-repeat #fff;
  }

  p {
    line-height: 30px;
    padding: 0 20px;
    background-color: $white-color;
    color: $dark-text-color;
    font-size: 20px;
    text-align: center;
  }

  &__link {
    padding: 16px 20px 0;
    background-color: $white-color;
  }

  a {
    display: inline-block;
    line-height: 48px;
    padding: 0 28px;
    background-color: $green-color;
    color: $white-color;
    font-size: 20px;
    border-radius: 4px;
    text-decoration: none;
  }
}
