@import 'variables';

.gac-filter-item, .gac-select {
  position: relative;
  height: 48px;
  border: 1px solid $border-color;
  background-color: $white-color;
  white-space: nowrap;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color .2s ease;
  &.gac-invalid {
    border-color: $red-color;
  }
  &:after {
    position: absolute;
    top: 50%;
    right: 22px;
    width: 10px;
    height: 6px;
    margin-top: -3px;
    background: url('../../images/sprite.svg') no-repeat 0 0;
    z-index: 2;
    content: '';
  }
  .gac-filter-item__value, .gac-select-value {
    position: relative;
    line-height: 46px;
    height: 46px;
    padding: 0 56px 0 16px;
    z-index: 3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .gac-filter-item__menu, .gac-select-menu {
    width: 260px;
    max-height: 408px;
    position: absolute;
    left: -1px;
    padding: 12px 0;
    border: 1px solid $border-color;
    background-color: $white-color;
    box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
    z-index: 101;
    border-radius: 2px;
    overflow-y: auto;
    li {
      display: block;
      .gac-filter-item__menu__link, .gac-select-link {
        position: relative;
        display: block;
        line-height: 32px;
        padding: 0 44px 0 16px;
        background-color: $white-color;
        font-size: 15px;
        color: $dark-text-color;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        user-select: none;
        &.gac-active:before,
        i {
          position: absolute;
          right: 20px;
          top: 13px;
          width: 14px;
          height: 10px;
          background: url('../../images/sprite.svg') no-repeat -40px -80px;
          content: "";
        }
        &.gac-multiple:not(.gac-active):before,
        span {
          position: absolute;
          right: 20px;
          top: 10px;
          width: 16px;
          height: 16px;
          border: 1px solid $border-color;
          border-radius: 2px;
          content: "";
        }
        &.gac-group-name {
          cursor: default;
          font-weight: bold;
        }
      }
      ul {
        padding-left: 14px;
      }
    }
    .gac-no-options {
      line-height: 32px;
      padding: 0 16px;
      color: $light-text-color;
      user-select: none;
    }
  }
  .gac-select-menu {
    width: calc(100% + 2px);
  }
  &.gac-clicked {
    &:before {
      background-position: -10px 0;
    }
  }
  .gac-filter-item__menu-colors {
    width: 190px;
    padding: 20px 16px 15px 16px;
    .gac-filter-item__checkbox {
      display: block;
      position: relative;
      padding-left: 32px;
      margin-bottom: 10px;
      color: $dark-text-color;
      font-size: 15px;
      cursor: pointer;
      input {
        display: none;
      }
      i {
        position: absolute;
        left: 3px;
        top: 50%;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        //background: url('../../images/sprite.svg') -40px -90px;
      }
      input:checked + i {
        background-position: -40px -130px;
      }
    }
    .gac-filter-item__checkboxs-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4px;
      padding-top: 11px;
      .gac-filter-item__checkbox-v2 {
        margin: 0 4px 8px;
        input {
          display: none;
        }
        input:checked + label {
          background-image: url('../../images/sprite.svg');
          background-repeat: no-repeat;
          background-position: -60px -80px;
        }
        input:checked + label[for='gac-filter-colors__white'] {
          background-position: -60px -100px;
        }
        label {
          display: block;
          width: 18px;
          height: 18px;
          border: 1px solid #E9EFF3;
          border-radius: 100%;
          cursor: pointer;
        }
        label[for='gac-filter-colors__red'] {
          background-color: #ff0000;
          border-color: #ff0000;
        }
        label[for='gac-filter-colors__orange'] {
          background-color: #fdb91a;
          border-color: #fdb91a;
        }
        label[for='gac-filter-colors__yellow'] {
          background-color: #ffee02;
          border-color: #ffee02;
        }
        label[for='gac-filter-colors__green'] {
          background-color: #00e101;
          border-color: #00e101;
        }
        label[for='gac-filter-colors__turquoise'] {
          background-color: #00d7e8;
          border-color: #00d7e8;
        }
        label[for='gac-filter-colors__blue'] {
          background-color: #0000ff;
          border-color: #0000ff;
        }
        label[for='gac-filter-colors__lilac'] {
          background-color: #c93ff7;
          border-color: #c93ff7;
        }
        label[for='gac-filter-colors__pink'] {
          background-color: #f8c1fe;
          border-color: #f8c1fe;
        }
        label[for='gac-filter-colors__white'] {
          background-color: #ffffff;
        }
        label[for='gac-filter-colors__gray'] {
          background-color: #bbbbbb;
          border-color: #bbbbbb;
        }
        label[for='gac-filter-colors__black'] {
          background-color: #000000;
          border-color: #000000;
        }
        label[for='gac-filter-colors__brown'] {
          background-color: #ae5700;
          border-color: #ae5700;
        }
      }
    }
  }
  .gac-filter-item__menu-inputs {
    width: 240px;
    padding-bottom: 23px;
    p {
      padding: 0 16px;
      margin: 3px 0 8px 0;
      color: $dark-text-color;
      font-size: 15px;
    }
    .gac-filter-item__inputs {
      display: flex;
      padding: 0 0 0 16px;
      input {
        width: 74px;
        height: 40px;
        padding: 0 10px 0 15px;
        border: 1px solid $border-color;
        color: $light-text-color;
        font-size: 15px;
      }
      .gac-filter-item__x {
        line-height: 40px;
        width: 25px;
        text-align: center;
      }
      .gac-filter-item__px {
        line-height: 40px;
        margin-left: 8px;
      }
    }
  }
  &#industry {
    .gac-select-menu {
      max-height: 474px;
    }
  }
}
.gac-select {
  input {
    height: 30px;
    width: calc(100% - 32px);
    margin: 0 16px 10px;
    padding: 0 6px;
    border: 1px solid $border-color;
    border-radius: 2px;
    font-size: 14px;
    color: $dark-text-color;
  }
}
.gac-selectv2 {
  max-width: 300px;
  padding-right: 22px;
  position: relative;
  i {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 18px;
    background: url("../../images/sprite.svg") -112px -240px;
    cursor: pointer;
  }
  &-value {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    line-height: 18px;
    color: $dark-text-color;
    font-size: 20px;
    font-weight: bold;
    &:before {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $dark-text-color;
      content: "";
    }
  }
  &-menu {
    min-width: 260px;
    width: 100%;
    max-height: 408px;
    position: absolute;
    left: -1px;
    padding: 6px 0;
    border: 1px solid $border-color;
    background-color: $white-color;
    box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
    z-index: 5;
    border-radius: 2px;
    overflow-y: auto;
    li {
      display: block;
      .gac-selectv2-link {
        display: block;
        line-height: 40px;
        padding: 0 26px 0 16px;
        font-size: 15px;
        color: $dark-text-color;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.gac-active {
          background: $green-color;
          color: $white-color;
        }
      }
      &:hover {
        .gac-selectv2-link {
          background: $green-color;
          color: $white-color;
        }
      }
    }
  }
}