@import "variables";

.gac-banner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid $green-color;
  max-width: 322px;
  width: 322px;
  box-sizing: border-box;

  .text {
    p {
      font-size: 13px;
      line-height: 20px;
    }

    .buttons {
      margin-top: 15px;

      a {
        font-size: 13px;
      }

      :first-child {
        height: 32px;
        line-height: 30px;
        background-color: $green-color;
        margin-right: 15px;
        min-width: auto;
      }

      :nth-child(2) {
        color: $green-color;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

    }
  }

  .icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.gac-banner.seo-brief.writing {
  position: absolute;
  right: 0px;
  top: -72px;
}

.gac-banner.seo-brief.blogging {
  position: relative;
  margin-bottom: 24px;
}

.gac-banner.seo-brief.mobile {
  position: relative;
  right: initial;
  top: initial;
  margin: 0 auto 24px auto;
}