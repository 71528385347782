.web-builders-subscription {
    padding: 24px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #fff;
    border: 1px #dadada solid;
    border-radius: 4px;
    box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.05);
    position: relative;

    @media (max-width: 767px) {
        padding-top: 0;
        align-items: stretch;
    }

    &--on-change-plan {
        & .web-builders-subscription__title {
            font-size: 23px;
            min-height: unset;
        }
    }

    &__icon {
        margin-bottom: 16px;
        width: 60px;
        aspect-ratio: 1/1;

        @media (max-width: 767px) {
            margin-top: -12px;
        }
    }

    &__specialists {
        margin-bottom: 24px;
        text-align: left;

        @media (min-width: 768px) {
            text-align: center;
        }

        @media (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @media (max-width: 576px) {
            font-size: 14px;
        }

        &-offer {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 3px;
            line-height: 150%;

            @media (max-width: 768px) {
                align-items: start;
                flex-wrap: wrap;
                line-height: toProcent(16, 19);
                width: fit-content;
            }

            &-value {
                white-space: nowrap;

                @media (max-width: 768px) {
                    max-width: 135px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                & span {
                    color: #00ab9c;
                    font-weight: 700;
                }
            }
        }

        &-separator {
            margin-top: 8px;
            margin-bottom: 8px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 24px;
            height: 24px;
            flex: none;

            @media (max-width: 768px) {
                margin-top: 0;
                margin-bottom: 0;
                margin-left: 14px;
                margin-right: 14px;
                width: 20px;
                height: 20px;
            }

            @media (max-width: 350px) {
                margin-left: 8px;
                margin-right: 8px;
            }

            & svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__title {
        margin-top: 0;
        font-size: 28px;
        font-weight: bold;
        line-height: 34px;
        min-height: 68px;
        margin-bottom: 24px;
        text-align: center;

        @media (max-width: 1200px) {
            font-size: 24px;
            line-height: toProcent(24, 29);
        }

        @media (max-width: 768px) {
            margin-bottom: 16px;
            min-height: unset;
            text-align: left;
        }

        & br {
            @media (max-width: 768px) {
                display: none !important;
            }
        }
    }

    &__price {
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 32px;

        @media (max-width: 767px) {
            position: absolute;
            right: 16px;
            text-align: right;
            top: 16px;
        }

        &:has(+ .web-builders-subscription__total) {
            margin-bottom: 0;
        }
    }

    &__total {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 20px;
        color: #afafaf;

        @media (max-width: 767px) {
            position: absolute;
            right: 16px;
            top: 45px;
        }
    }

    &__button {
        margin-top: auto;
        padding: 12px 16px;
        width: 100%;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #ff3666;

        &.is-current {
            background-color: #e9eff3;
            border-color: #e9eff3;
            pointer-events: none;
        }

        &:hover {
            background: #ff3666;
        }
    }
}
