/* CSS animation */
.gac-full-image-enter {
  opacity: 0;
}
.gac-full-image-enter-active {
  opacity: 1;
  transition: opacity 300ms ease;
}
.gac-full-image-exit {
  opacity: 1;
}
.gac-full-image-exit-active {
  opacity: 0;
  transition: opacity 300ms ease;
}

.gac-project-details-default-enter {
  height: 0;
  top: 0;
}
.gac-project-details-default-enter-active {
  height: 203px;
  top: -5px;
  transition: height 300ms ease, top 300ms ease;
}
.gac-project-details-default-exit {
  height: 203px;
  top: -5px;
}
.gac-project-details-default-exit-active {
  height: 0;
  top: 0;
  transition: height 300ms ease, top 300ms ease;
}

.gac-project-details-current-enter {
  height: 0;
  top: 0;
}
.gac-project-details-current-enter-active {
  height: 165px;
  top: -5px;
  transition: height 300ms ease, top 300ms ease;
}
.gac-project-details-current-exit {
  height: 165px;
  top: -5px;
}
.gac-project-details-current-exit-active {
  height: 0;
  top: 0;
  transition: height 300ms ease, top 300ms ease;
}

/* CSSTransition animation */
.gac-opacity-200-enter {
  opacity: 0;
}
.gac-opacity-200-enter-active {
  opacity: 1;
  transition: all 200ms ease;
}
.gac-opacity-200-exit {
  opacity: 1;
}
.gac-opacity-200-exit-active {
  opacity: 0;
  transition: all 200ms ease;
}
.gac-opacity-300-enter {
  opacity: 0;
}
.gac-opacity-300-enter-active {
  opacity: 1;
  transition: all 300ms ease;
}
.gac-opacity-300-exit {
  opacity: 1;
}
.gac-opacity-300-exit-active {
  opacity: 0;
  transition: all 300ms ease;
}
/* Overlay */
.gac-overlay-enter {
  opacity: 0;
}
.gac-overlay-enter-active {
  opacity: 1;
  transition: all 500ms ease;
}
.gac-overlay-exit {
  opacity: 1;
}
.gac-overlay-exit-active {
  opacity: 0;
  transition: all 500ms ease;
}
/* Feedback modal */
.gac-feedback-modal-an-enter {
  opacity: 0;
  bottom: -350px;
}
.gac-feedback-modal-an-enter-active {
  opacity: 1;
  bottom: 100px;
  transition: all 400ms ease;
}

/* Сarousel */
.gac-categories-carousel-enter {
  opacity: 0;
  transform: scaleY(0);
  transform-origin:top;
}
.gac-categories-carousel-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transform-origin:top;
  transition: all 300ms ease;
}
.gac-categories-carousel-exit {
  opacity: 1;
  transform: scaleY(1);
  transform-origin:top;
}
.gac-categories-carousel-exit-active {
  opacity: 0;
  transform: scaleY(0);
  transform-origin:top;
  transition: all 300ms ease;
}

/* Mobile menu */
.gac-mobile-menu-enter {
  left: -320px;
}
.gac-mobile-menu-enter-active {
  left: 0;
  transition: left 500ms cubic-bezier(0.46, 0.01, 0.32, 1);
}
.gac-mobile-menu-exit {
  left: 0;
}
.gac-mobile-menu-exit-active {
  left: -320px;
  transition: left 500ms cubic-bezier(0.46, 0.01, 0.32, 1);
}

/* Messages center */
.gac-messages-center-wrap-enter {
  right: -400px;
}
.gac-messages-center-wrap-enter-active {
  right: 0;
  transition: all 300ms ease;
}
.gac-messages-center-wrap-exit {
  right: 0;
}
.gac-messages-center-wrap-exit-active {
  right: -400px;
  transition: all 300ms ease;
}