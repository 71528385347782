/* Overlay */
.gac-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(72, 75, 88, 0.55);
  z-index: 99990;
}
.gac-blocking-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999998;
}