$font: 'Open Sans', sans-serif;

$white-color: #ffffff;
$bg-color: #fafcfe;
$dark-text-color: #1E1E1E;
$light-text-color: #6f6f7a;
$green-color: #59C69F;
$blue-color: #74a3d2;
$red-color: #EE5050;
$yellow-color: #F7C300;
$yellow-color-dark: #EDB438;
$yellow-color-dark-2: #ffb200;
$btn-yellow-color: #F7C300;
$border-color: #D9D9D9;
$grey-color: #afaeaf;
$disabled-color: #e9eff3;
$light-color: #fdfeff;
$granite-gray-color: #343744;
$bright-red-orange-color: #F44A10;
