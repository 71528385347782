@import 'variables';

.gac-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.32);
  z-index: 999999;
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 320px;
    max-width: 400px;
    padding: 40px 30px 30px;
    background-color: $white-color;
    border-radius: 8px;
  }
  &__icon {
    margin-bottom: 30px;
    img {
      max-width: 80px;
    }
  }
  &__text {
    line-height: 26px;
    font-size: 15px;
    text-align: center;
  }
}

.gac-warning {
  color: $yellow-color-dark-2;
}
.gac-error {
  color: $red-color;
  span {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
.gac-success {
  color: $green-color;
}