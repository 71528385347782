@import 'variables';

.gac-calendar {
  padding-top: 80px;
  padding-bottom: 130px;
  &.gac-mobile-view {
    //padding-top: 227px;
    padding-bottom: 24px;
    &.gac-full-mobile-grid {
      //padding-top: 423px;
    }
  }
  .gac-calendar-nav {
    .gac-calendar-nav__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      transition: margin-bottom .2s ease;
      &.gac-filters-on {
        margin-bottom: 16px;
      }
      .gac-calendar-nav__buttons {
        width: 33.3333%;
        display: flex;
        .gac-filters-btn {
          margin-right: 16px;
        }
      }
      .gac-calendar-nav__date {
        width: 33.3333%;
        max-width: 272px;
        margin: 0 16px;
        display: flex;
        justify-content: center;
        .gac-calendar-nav__date__prev,
        .gac-calendar-nav__date__next {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          cursor: pointer;
          svg path {
            fill: $dark-text-color;
            transition: fill .2s ease;
          }
          &:hover {
            svg path {
              fill: #54e5a0;
            }
          }
          &.gac-calendar-nav__date__prev {
            margin-right: auto;
          }
          &.gac-calendar-nav__date__next {
            margin-left: auto;
          }
        }
        .gac-calendar-nav__date__month,
        .gac-calendar-nav__date__year{
          line-height: 24px;
          margin: 0 5px;
          color: $dark-text-color;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .gac-calendar-nav__tabs {
        width: 33.3333%;
        display: flex;
        justify-content: flex-end;
        visibility: hidden;
        .gac-calendar-nav__tabs-wrap {
          display: flex;
          border: 1px solid $border-color;
          border-radius: 2px 2px 0 0;
          .gac-calendar-nav__tabs__item {
            position: relative;
            width: 148px;
            height: 46px;
            line-height: 44px;
            background-color: transparent;
            color: $light-text-color;
            font-size: 15px;
            text-align: center;
            cursor: pointer;
            transition: background-color .3s ease;
            &:first-child {
              width: 149px;
              border-right: 1px solid $border-color;
            }
            &.gac-active {
              background-color: $white-color;
              &:before {
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1px;
                height: 2px;
                background: $green-color;
                border-radius: 2px;
                content: "";
              }
            }
          }
        }
      }
    }
    .gac-calendar-filters {
      display: flex;
      position: relative;
      .gac-filter-item {
        width: 215.2px;
        margin-right: 16px;
        margin-bottom: 24px;
        &:last-child {
          margin-right: 0;
        }
        .gac-filter-item__menu {
          //width: 100%;
        }
      }
      .gac-filters-btns {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .gac-calendar-content {
    position: relative;
    z-index: 1;
    background: $white-color;
    border: 1px solid $border-color;
    border-radius: 2px 2px 0 0;
    .gac-calendar-content__header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      .gac-calendar-content__header__day {
        flex: 1 1 calc(100%/7);
        line-height: 48px;
        text-align: center;
      }
    }
    .gac-calendar-content__grid {
      position: relative;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: $border-color;
      min-height: 971px;
      .gac-calendar__cell {
        width: calc((100% - 6px) / 7);
        max-width: 161.7px;
        min-height: 162px;
        margin-bottom: 1px;
        background-color: $white-color;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $light-text-color;
        &:nth-child(7n+1) {}
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4),
        &:nth-last-child(5),
        &:nth-last-child(6),
        &:nth-last-child(7) {
          margin-bottom: 0;
        }
        .gac-calendar__cell__add {
          position: relative;
          padding: 0 0 0 32px;
          border: 0;
          height: 24px;
          line-height: 22px;
          margin-right: auto;
          background-color: transparent;
          color: $dark-text-color;
          font-size: 15px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          cursor: pointer;
          i {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            //background: url('../../images/sprite.svg') no-repeat -155px -80px;
          }
          &:focus {
            outline: none;
          }
        }
        .gac-calendar__cell__header {
          display: flex;
          padding: 9px 10px 9px 16px;
          .gac-day {
            display: flex;
            margin-left: auto;
            .gac-day-label {
              min-width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              text-align: center;
              color: $light-text-color;
              border-radius: 100%;
            }
          }
        }
        .gac-calendar__cell__content {
          padding: 0 8px 4px 8px;
        }

        &.disabled {
          background: url('../../images/bg-disabled.png') repeat top left;
          .gac-day {
            opacity: 0.5;
          }
        }

        &.today {
          z-index: 1;
          box-shadow: 0 0 0 2px $blue-color;
          border-radius: 1px;
          .gac-day-today-label {
            line-height: 22px;
            color: $blue-color;
            font-size: 15px;
          }
        }
      }
    }

  }
}
.gac-calendar-project {
  cursor: pointer;
  background-color: $white-color;
  border-radius: 2px;
  border: 1px solid $border-color;
  border-right-width: 4px;
  padding: 8px 8px 4px 8px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $dark-text-color;
  &.auto,
  &.approved {
    border-right-color: $green-color;
  }
  &.designing,
  &.writing,
  &.editing {
    border-right-color: $blue-color;
  }
  &.published {
    border-right-color: $granite-gray-color;
  }
  &.revision {
    border-right-color: $blue-color;
  }
  &.draft {
    border-right-color: $grey-color;
  }
  &.matching {
    border-right-color: $green-color;
  }
  &.pitching {
    //border-right-color: $bright-red-orange-color;
    border-right-color: $red-color;
  }
  &.approval {
    border-right-color: $red-color;
  }
  &.cancelled {
    border-right-color: $border-color;
  }
}
.gac-calendar-project__note,
.gac-calendar-project__title {
  margin-bottom: 6px;
  margin-right: 3px;
}
.gac-calendar-project__title {
  overflow: hidden;
}
.gac-calendar-project__note {
  &.publish {
    color: $blue-color;
  }
  &.overdue {
    color: $red-color;
  }
}
.gac-calendar-project__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 3px 0;
  color: $dark-text-color;
  &.gac-without-author {
    justify-content: flex-end;
  }
  .gac-project-type {
    margin-top: 5px;
  }
  .gac-calendar-project__author {
    height: 21px;
    line-height: 18px;
    padding-top: 5px;
    .gac-calendar-project__author__avatar {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      vertical-align: top;
      &.gac-empty-avatar {
        background: url('../../images/sprite.svg') -180px -80px;
      }
      img {
        border-radius: 100%;
      }
    }
  }
  .gac-calendar-project__status {
    display: flex;
    align-items: baseline;
    height: 21px;
    line-height: 18px;
    padding-top: 5px;
    .gac-calendar-project__status__dot {
      width: 10px;
      height: 10px;
      margin-right: 6px;
      background: url('../../images/sprite.svg') no-repeat 0 0;
      .auto &,
      .approved & {
        background-position: -175px -115px;
      }
      .designing &,
      .writing &,
      .editing & {
        background-position: -165px -115px;
      }
      .published & {
        background-position: -185px -105px;
      }
      .revision & {
        background-position: -165px -115px;
      }
      .draft & {
        background-position: -155px -125px;
      }
      .matching & {
        background-position: -155px -115px;
      }
      .pitching & {
          background-position: -165px -125px;
      }
      .approval & {
        background-position: -165px -105px;
      }
      .cancelled & {
        background-position: -155px -105px;
      }
    }
    .gac-calendar-project__status__label {
      text-transform: capitalize;
    }
  }
}
.gac-calendar__cell__more {
  display: block;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $light-text-color;
  span {
    cursor: pointer;
  }
}