/* Fill */
.gac-project-wrapper .gac-project-sidebar .gac-project-pages li:not(.gac-disabled):hover i svg path,
.gac-project-wrapper .gac-project-sidebar .gac-project-pages li.gac-active i svg path,
.gac-account-wrapper .gac-account-sidebar .gac-account-pages li:not(.gac-disabled):hover i svg path,
.gac-account-wrapper .gac-account-sidebar .gac-account-pages li.gac-active i svg path,
.gac-main-menu li span svg path,
.gac-btn-v3 i.gac-svg svg path,
.gac-btn-v3 i.gac-arrow svg path,
.gac-calendar__cell__add svg path,
.gac-cart .gac-add-project i svg path,
.gac-modal.gac-referral-modal .gac-referral-desc div i svg path,
.gac-filters-btn:not(.gac-filters-btn-active):hover svg path,
.gac-filters-btn:not(.gac-filters-btn-active):focus svg path,
.gac-filters-btn:not(.gac-filters-btn-active):active svg path,
.gac-empty-state-img svg path,
.gac-sharing-desc i svg path,
.gac-new-project .gac-form-row .gac-form-field.gac-field-quantity .gac-increase-qty svg path,
.gac-new-project .gac-form-row .gac-form-field.gac-field-quantity .gac-decrease-qty svg path,
.gac-project-comment-btns i svg path,
.gac-modal.gac-writers-modal .gac-writers-list .gac-writer-item-all i svg path,
.gac-modal.gac-writers-modal .gac-writers-list .gac-writer-item-all i.gac-icon-deselect svg path,
.gac-btn-rounded i svg path,
.gac-edit-btn i svg path,
.gac-cart-payment-methods .gac-cart-methods-item input:checked + span svg path,
.gac-account-wrapper .gac-create-account-from .gac-form-radio-input label input:checked + span svg path,
.gac-users-list .gac-user-item.gac-close i svg path,
.gac-modal-subscription .gac-keywords-radio input:checked + span svg path,
.gac-checkbox input[type="checkbox"]:checked + i svg path,
.gac-free-images .gac-images-filters .gac-filter-item__safesearch input:checked + i svg path,
.gac-calendar .gac-calendar-nav .gac-calendar-nav__header .gac-calendar-nav__date .gac-calendar-nav__date__prev:hover svg path,
.gac-calendar .gac-calendar-nav .gac-calendar-nav__header .gac-calendar-nav__date .gac-calendar-nav__date__next:hover svg path,
.gac-filter-item .gac-filter-item__menu-colors .gac-filter-item__checkbox input:checked + i svg path,
.gac-select .gac-filter-item__menu-colors .gac-filter-item__checkbox input:checked + i svg path,
.gac-filters-btn:not(.gac-filters-btn-active):hover i svg path,
.gac-filters-btn:not(.gac-filters-btn-active):focus i svg path,
.gac-filters-btn:not(.gac-filters-btn-active):active i svg path,
.gac-calendar-nav__mobile-btn:hover i svg path,
.gac-mobile-menu .gac-mobile-menu-settings > span i svg path,
.gac-mobile-menu .gac-mobile-menu-referral span i svg path,
.gac-modal.gac-rate-writer-modal .gac-share-feedback input[type="checkbox"]:checked + i svg path,
.gac-modal.gac-rate-writer-modal .gac-remove-writer input[type="checkbox"]:checked + i svg path,
.gac-sub-desc .gac-items .gac-item i svg path,
.gac-add-project-mobile-btn i svg path,
.gac-add-project-mobile-btn:hover i svg path,
.gac-client-feedback-title i svg path,
.gac-modal-subscription.gac-setup h1 i svg path,
.gac-btn-timelapse i svg path,
.gac-step-number i svg path {
    fill: #59c69f;
}
