@import 'variables';

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  padding: 10px;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 0 30px 0 rgba(159, 177, 188, 0.4);
  z-index: 99999999;
  .spinner {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 4px solid $border-color;
    border-top: 4px solid $grey-color;
    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;
    &:after {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
}
.gac-modal {
  .spinner-wrap {
    position: absolute;
  }
}
.gac-maintenance-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $white-color;
  z-index: 99999;
  &__image {
    transform: scale(0.8);
  }
  &__text {
    line-height: 28px;
    padding: 0 15px;
    font-size: 18px;
    font-weight: bold;
  }
}
.gac-scroll-up-btn {
  display: flex;
  position: fixed;
  right: 130px;
  bottom: 22px;
  cursor: pointer;
  z-index: 999999;
}
