@import 'variables';
/* Filters button */
.gac-filters-btn {
  position: relative;
  min-width: 118px;
  line-height: 46px;
  padding: 0 25px 0 48px;
  border: 1px solid $border-color;
  background-color: $white-color;
  color: $light-text-color;
  font-size: 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: color .2s ease, border-color .2s ease;
  user-select: none;
  i {
    position: absolute;
    left: 23px;
    top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 14px;
    svg path {
      transition: fill .2s ease;
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $green-color;
    border-color: rgba(67, 185, 147, 0.2);
    i {
      svg path {
        fill: $green-color;
      }
    }
  }
  &:focus, &:active {
    border-color: $green-color;
    outline: none;
  }
  &.gac-filters-btn-active {
    border-color: transparent;
    background: $green-color;
    color: $white-color!important;
    i {
      background-position: -40px -290px;
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}
/* Buttons */
.gac-btn {
  display: inline-block;
  position: relative;
  min-width: 150px;
  height: 60px;
  line-height: 60px;
  box-shadow: none;
  border: 0;
  padding: 0 20px;
  margin: 0;
  background-color: $green-color;
  color: $white-color;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  transition: opacity .2s ease;
  z-index: 1;
  user-select: none;
  text-decoration: none;
  &:hover {
    color: $white-color;
    text-decoration: none;
    opacity: 0.8;
  }
  &:active,
  &:focus {
    color: $white-color;
    text-decoration: none;
  }
  &.gac-btn-32 {
    height: 32px;
    line-height: 30px;
    border-radius: 16px;
    font-size: 15px;
    font-weight: normal;
  }
  &.gac-btn-xs {
    height: 24px;
    min-width: auto;
    line-height: 22px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: normal;
  }
  &.gac-btn-s {
    height: 40px;
    line-height: 38px;
    border-radius: 20px;
  }
  &.gac-btn-m {
    height: 48px;
    line-height: 46px;
    border-radius: 23px;
  }
  &.gac-disabled,
  &.gac-btn-disable,
  &.gac-btn-disable-v2 {
    cursor: default;
    background-color: $disabled-color;
    &:hover {
      opacity: 1;
    }
  }
  &.gac-btn-disable-v2 {
    //display: flex;
    //align-items: center;
    color: $light-text-color;
    font-weight: normal;
    i {
      display: inline-block;
      width: 14px;
      height: 10px;
      margin-right: 10px;
      background: url("../../images/sprite.svg") 0 -650px;
    }
  }
  &.gac-btn-red {
    background: $red-color;
  }
  &.gac-btn-subs {
    display: flex;
    align-items: center;
    min-width: inherit;
    .gac-svg {
      display: flex;
      align-items: center;
      margin-right: 10px;
      svg path {
        fill: $white-color;
      }
    }
  }
}
.gac-btn-v2 {
  position: relative;
  min-width: 150px;
  height: 60px;
  line-height: 56px;
  box-shadow: none;
  padding: 0 20px;
  margin: 0;
  border: 2px solid $green-color;
  background-color: $white-color;
  color: $green-color;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  transition: opacity .2s ease;
  z-index: 1;
  user-select: none;
  text-decoration: none;
  &:hover {
    color: $green-color;
    text-decoration: none;
    opacity: 0.8;
  }
  &.gac-btn-32 {
    height: 32px;
    line-height: 28px;
    border-radius: 16px;
    font-size: 15px;
    font-weight: normal;
  }
  &.gac-btn-s {
    height: 40px;
    line-height: 36px;
    border-radius: 20px;
  }
  &.gac-btn-with-icon {
    padding-right: 38px;
    .gac-btn-icon {
      position: absolute;
      right: 14px;
      top: 50%;
      width: 10px;
      height: 6px;
      margin: -3px 0 0 0;
      background: url('../../images/sprite.svg') -92px -239px;
    }
  }
}
.gac-btn-v3 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  height: 40px;
  padding: 0 15px 0 15px;
  background: $green-color;
  border-radius: 20px;
  color: $green-color;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  &:active {
    text-decoration: none;
  }
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid transparent;
    background-color: $white-color;
    background-clip: padding-box;
    transition: border .2s ease;
    content: '';
    z-index: -1;
    border-radius: 20px;
  }
  &:hover {
    text-decoration: none;
  }
  &.gac-btn-red {
    background: $red-color;
    color: $red-color;
    i.gac-svg svg path {
      fill: $red-color!important;
    }
  }
  .gac-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 6px;
    margin-left: 12px;
  }
  i.gac-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  i:not(.gac-arrow):not(.gac-svg) {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: url("../../images/sprite.svg") 0 0;
    &.gac-icon-1 {
      background-position: -90px -520px;
    }
    &.gac-icon-2 {
      background-position: -110px -520px;
    }
    &.gac-icon-3 {
      background-position: -130px -520px;
    }
    &.gac-icon-4 {
      background-position: -150px -520px;
    }
    &.gac-icon-5 {
      background-position: -170px -520px;
    }
    &.gac-icon-6 {
      background-position: -190px -520px;
    }
    &.gac-icon-7 {
      background-position: -210px -520px;
    }
    &.gac-icon-8 {
      background-position: -230px -520px;
    }
    &.gac-icon-9 {
      background-position: -40px -610px;
    }
    &.gac-icon-10 {
      background-position: -60px -610px;
    }
    &.gac-icon-11 {
      background-position: -90px -610px;
    }
  }
  span {
    position: relative;
    //top: 1px;
    line-height: 16px;
  }
}
.gac-btn-default {
  min-width: 90px;
  line-height: 46px;
  padding: 0 20px;
  border: 1px solid $border-color;
  background-color: $white-color;
  color: $light-text-color;
  font-size: 15px;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  transition: color .2s ease, border-color .2s ease;
  user-select: none;
  &:not(.gac-disabled):hover,
  &:not(.gac-disabled):focus,
  &:not(.gac-disabled):active {
    color: $green-color;
    border-color: rgba(67, 185, 147, 0.2);
    i {
      background-position: -60px -60px;
    }
  }
  &:not(.gac-disabled):focus, &:not(.gac-disabled):active {
    border-color: $green-color;
    outline: none;
  }
  &.gac-disabled {
    color: $grey-color;
    cursor: default;
  }
}
.gac-btn-rounded {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  cursor: pointer;
  user-select: none;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid $border-color;
    border-radius: 100%;
    content: "";
  }
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    //background: url('../../images/sprite.svg') no-repeat -119px -314px;
  }
  &.gac-active {
    background: $green-color;
    i {
      svg {
        path {
          fill: $white-color
        }
      }
      //background-position: -110px -370px;
    }
    &:before {
      border: 0;
    }
  }
}
.gac-btn-add-info {
  display: flex;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  span {
    text-decoration: underline;
    margin-right: 12px;
  }
  &:hover {
    span {
      text-decoration: none;
    }
  }
}
.gac-btn-timelapse {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid $border-color;
  cursor: pointer;
  user-select: none;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    //background: url('../../images/sprite.svg') no-repeat -75px -455px;
  }
}
.gac-subscribe-btn {
  display: block;
  height: 48px;
  line-height: 44px;
  border: 1px solid $btn-yellow-color;
  border-radius: 4px;
  color: $dark-text-color;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
  &:hover {
    background-color: $btn-yellow-color;
    color: $white-color;
  }
  &.gac-disabled {
    background-color: $disabled-color!important;
    border-color: $disabled-color!important;
    color: $light-text-color!important;
    cursor: default;
  }
}

/* Calendar btn */
.gac-add-project-mobile-btn,
.gac-calendar-nav__mobile-btn {
  position: relative;
  width: 48px;
  height: 48px;
  border: 1px solid $border-color;
  border-radius: 2px;
  background-color: $white-color;
  cursor: pointer;
  user-select: none;
  transition: border-color .2s ease;
  &:hover,
  &:focus,
  &:active {
    border-color: rgba(67, 185, 147, 0.2);
    i {
      svg path {
        fill: $green-color;
      }
    }
  }
  &:focus, &:active {
    border-color: $green-color;
    outline: none;
  }
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -10px;
    width: 20px;
    height: 22px;
    //background: url('../../theme/images/sprite.svg') -90px -200px;
    svg path {
      transition: fill .2s ease;
    }
  }
  &.gac-add-project-mobile-btn {
    i {
      width: 18px;
      height: 18px;
      margin: -9px 0 0 -9px;
      //background: url('../../theme/images/sprite.svg') 0 -230px;
    }
  }
  &.gac-calendar-nav__mobile-btn-active {
    border-color: transparent;
    background: $green-color;
    i {
      //background-position: -130px -200px;
      svg path {
        fill: $white-color!important;
      }
    }
  }
}

/*  Manage */
.gac-show-project-details-btn {
  width: 40px;
  height: 40px;
  margin: 14px 15px 0 0;
  border: 1px solid $border-color;
  background: url('../../images/sprite.svg') -110px -230px;
  border-radius: 100%;
  cursor: pointer;
  user-select: none;
  &.gac-is-open {
    background: url('../../images/sprite.svg') -190px -230px;
  }
}

.gac-edit-btn {
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid $border-color;
  border-radius: 100%;
  cursor: pointer;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px
  }
  &.gac-add {
    &:hover {
      background: $green-color;
      border-color: transparent;
      i {
        svg {
          path {
            fill: $white-color;
          }
        }
      }
    }
  }
  &.gac-remove {
    background: $green-color;
    border-color: transparent;
    i {
      background: url("../../images/sprite.svg") -110px -370px;
    }
  }
}

.gac-btn-with-list {
  position: relative;
  .gac-btn {
    display: flex;
    align-items: center;
    .gac-arrow {
      width: 10px;
      height: 6px;
      margin-left: 12px;
      background: url("../../images/sprite.svg") -15px -650px;
    }
  }
  .gac-design-source-list,
  ul {
    position: absolute;
    top: 40px;
    left: 0;
    min-width: calc(100% - 16px);
    background-color: $white-color;
    border: 1px solid $border-color;
    box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
    z-index: 10;
    .gac-design-source-item,
    li {
      display: block;
      line-height: 40px;
      padding: 0 30px 0 16px;
      font-size: 15px;
      text-decoration: none;
      background-color: transparent;
      transition: color .3s ease, background-color .3s ease;
      white-space: nowrap;
      cursor: pointer;
      a {
        color: $light-text-color;
        font-size: 15px;
        text-decoration: none;
      }
      &:hover {
        background-color: $border-color;
        a {
          color: $green-color;
        }
      }
    }
  }
}

.gac-button {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  border: 0;
  color: $white-color;
  font-size: 20px;
  border-radius: 4px;
  background-color: $green-color;
  text-align: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  transition: background-color .2s ease, opacity .2s ease;
  &:hover {
    background-color: #7ad1b2;
  }
}