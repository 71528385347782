@import 'variables';

.gac-proposal-page {
  .gac-proposal-wrap {
    max-width: 944px;
    margin: 0 auto;
  }
  padding-top: 120px;
  padding-bottom: 130px;
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 30px;
  }
  .gac-proposal-title {
    line-height: 56px;
    margin-bottom: 32px;
    font-size: 45px;
    font-weight: bold;
  }
  .gac-proposal-video {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (944 / 532) */
    margin-bottom: 56px;
    iframe {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
  .gac-proposal-text {
    display: flex;
    justify-content: space-between;
    .gac-proposal-text-wrap {
      flex: 1 1 auto;
      max-width: 690px;
      a {
        color: $dark-text-color;
        font-size: 15px;
        &:hover {
          text-decoration: none;
          color: $green-color;
        }
      }
      p {
        line-height: 22px;
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      & > ol {
        margin-bottom: 22px;
      }
      ol {
        margin-bottom: 22px;
        list-style: none;
        counter-reset: li;
        li {
          position: relative;
          padding-left: 22px;
          margin-bottom: 6px;
          line-height: 22px;
          counter-increment: li;
          ol {
            padding-top: 6px;
          }
          &:before {
            position: absolute;
            left: 0;
            color: $green-color;
            content: counter(li);
          }
        }
      }
      & > ul {
        margin-bottom: 22px;
      }
      ul {
        list-style: none;
        li {
          position: relative;
          padding-left: 22px;
          margin-bottom: 6px;
          line-height: 22px;
          ul {
            padding-top: 6px;
          }
          &:before {
            position: absolute;
            left: 0;
            top: 8px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: $green-color;
            content: "";
          }
          &:after {
            position: absolute;
            left: 2px;
            top: 10px;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: $white-color;
            content: "";
          }
        }
      }
    }
    .gac-proposal-logo {
      padding-top: 50px;
      img {
        width: auto;
        height: auto;
        max-width: 150px;
        max-height: 150px;
      }
    }
  }
  .gac-proposal-manager {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 153px;
    & > p {
      line-height: 22px;
      width: 100%;
      margin-bottom: 8px;
      color: $light-text-color;
    }
    .gac-manager-ava {
      margin-right: 16px;
      img {
        height: auto;
        max-width: 56px;
        border-radius: 100%;
      }
    }
    .gac-manager-data {
      margin-top: -4px;
      p {
        line-height: 22px;
        color: $light-text-color;
        a {
          font-size: 15px;
          color: #5688bb;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .gac-proposal-details-btn {
    display: flex;
    justify-content: center;
    //margin: 56px 0 100px;
    .gac-btn {
      height: 56px;
      min-width: 296px;
      line-height: 56px;
      text-decoration: none;
      &:hover {
        color: $white-color;
      }
    }
  }
  .gac-section-one {
    position: relative;
    border-top: 1px solid $disabled-color;
    border-bottom: 1px solid $disabled-color;
    padding: 153px 0 160px;
    background-color: $white-color;
    .gac-section-wrapper {
      max-width: 944px;
      margin: 0 auto;
    }
    .gac-section-one-data {
      display: flex;
      margin-bottom: 45px;
      ul {
        list-style: none;
        margin-bottom: 22px;
        margin-left: 260px;
        &:first-child {
          margin-left: 0;
        }
        li {
          position: relative;
          padding-left: 22px;
          margin-bottom: 6px;
          line-height: 22px;
          &:before {
            position: absolute;
            left: 0;
            top: 50%;
            width: 10px;
            height: 10px;
            margin-top: -4px;
            border-radius: 100%;
            background: $green-color;
            content: "";
          }
          &:after {
            position: absolute;
            left: 2px;
            top: 50%;
            width: 6px;
            height: 6px;
            margin-top: -2px;
            border-radius: 100%;
            background: $white-color;
            content: "";
          }
        }
      }
    }
    .gac-section-one-videos {
      margin-bottom: 70px;
      display: flex;
      .gac-video-item-wrap {
        width: calc(50% - 30px);
      }
      .gac-video-item {
        position: relative;
        padding: 100% 0 0 0;
        iframe {
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
      }
    }
    .gac-proposal-clients-logo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: auto;
      }
    }
    .gac-proposal-details-btn {
      position: absolute;
      left: 50%;
      top: -28px;
      margin-left: -148px;
      &:before {
        position: absolute;
        left: -15px;
        top: 50%;
        width: 15px;
        height: 1px;
        margin-top: -1px;
        background-color: $white-color;
        content: "";
      }
      &:after {
        position: absolute;
        right: -15px;
        top: 50%;
        width: 15px;
        height: 1px;
        margin-top: -1px;
        background-color: $white-color;
        content: "";
      }
    }
  }
  .gac-section-two {
    padding: 152px 0 75px 0;
    .gac-bundles {
      padding-bottom: 0;
      justify-content: space-between;
      .gac-bundle-item {
        width: 165px;
        height: 165px;
        padding-top: 27px;
        margin: 0;
        background-color: $white-color;
        border-radius: 2px;
        &.gac-item-popular {
          padding-top: 34px;
        }
        h3 {
          margin-bottom: 12px;
        }
        p {
          margin-bottom: 22px;
        }
        h4 {
          margin-bottom: 15px;
        }
        .gac-popular-label {
          top: -18px;
          line-height: 33px;
          height: 32px;
        }
      }
    }
  }
  .gac-section-title {
    line-height: 40px;
    font-size: 30px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1169px) {
  .gac-support-phone {
    margin-right: 15px;
  }
}
@media only screen and (max-width: 973px) {
  .gac-proposal-page {
    padding-top: 72px;
    .gac-proposal-wrap {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
    .gac-proposal-title {
      line-height: 40px;
      margin-bottom: 16px;
      font-size: 31px;
    }
    .gac-proposal-video {
      margin-bottom: 24px;
    }
    .gac-proposal-text {
      flex-direction: column-reverse;
      .gac-proposal-text-wrap {
        max-width: 100%;
      }
      .gac-proposal-logo {
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
        img {
          max-height: 50px;
        }
      }
    }
    .gac-proposal-manager {
      margin-bottom: 92px;
    }
    .gac-section-title {
      line-height: 26px;
      font-size: 20px;
    }
    .gac-section-one {
      padding-top: 91px;
      padding-bottom: 96px;
      .gac-section-one-data {
        justify-content: space-between;
        margin-bottom: 23px;
        ul {
          width: calc(50% - 15px);
          margin-left: 0;
        }
      }
      .gac-section-one-videos {
        margin-bottom: 48px;
        .gac-video-item-wrap {
          width: calc(50% - 15px);
          &:first-child {
            margin-right: 30px!important;
          }
        }
      }
    }
    .gac-section-two {
      padding-top: 88px;
      padding-bottom: 0;
      .gac-section-title {
        margin-bottom: 41px!important;
      }
      .gac-bundles {
        .gac-bundle-item {
          width: 134px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .gac-proposal-details-btn + .gac-section-title {
      margin-bottom: 24px!important;
    }
    .gac-section-one-data + .gac-section-title {
      margin-bottom: 24px!important;
    }
    .gac-section-one-videos + .gac-section-title {
      margin-bottom: 24px!important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .gac-proposal-page {
    .gac-section-one {
      .gac-section-one-data {
        flex-direction: column;
        margin-bottom: 45px;
        ul {
          width: 100%;
          margin-left: 0;
          margin-bottom: 0;
        }
      }
      .gac-section-one-videos {
        flex-direction: column;
        .gac-video-item-wrap {
          width: 100%;
          &:first-child {
            margin-right: 0!important;
          }
        }
        .gac-video-item-wrap + .gac-video-item-wrap {
          margin-top: 20px;
        }
      }
      .gac-proposal-clients-logo {
        flex-wrap: wrap;
        justify-content: space-around;
        .gac-break-line {
          width: 100%;
          margin-bottom: 23px;
        }
      }
    }
    .gac-section-two {
      .gac-bundles {
        flex-direction: column;
        .gac-bundle-item {
          height: auto;
          width: 100%;
          padding: 0 10px 23px;
          margin: 0 0 24px 0;
          background-color: $white-color;
          &:last-child {
            margin-bottom: 8px;
          }
          &.gac-item-popular {
            padding-top: 0;
            margin-top: 44px;
            border: 2px solid $yellow-color;
          }
          h3 {
            line-height: 58px;
            font-size: 23px;
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: 27px;
            border-bottom: 1px solid $border-color;
          }
          h4 {
            margin-bottom: 9px;
            color: $light-text-color;
            font-size: 18px;
            font-weight: bold;
          }
          span {
            margin-bottom: 21px;
            color: #74a3d2;
            font-size: 16px;
            font-weight: bold;
          }
          .gac-popular-label {
            width: calc(100% + 4px);
            left: -2px;
            top: -46px;
            line-height: 46px;
            height: 46px;
            font-size: 16px;
          }
          .gac-btn {
            width: auto;
            min-width: 140px;
          }
        }
      }
    }
  }
  .gac-support-phone {
    height: 55px;
    img {
      max-width: 32px;
      margin-right: 17px;
    }
    i {
      margin-right: 0;
    }
    span {
      display: none;
    }
  }
}
@media only screen and (max-width: 479px) {
  .gac-proposal-page {
    .gac-section-one {
      .gac-proposal-clients-logo {
        img {
          max-width: 130px;
        }
      }
    }
  }
}
@media only screen and (max-width: 374px) {
  .gac-proposal-page {
    .gac-section-one {
      .gac-proposal-details-btn {
        margin-left: -130px!important;
        .gac-btn {
          min-width: 260px;
        }
      }
    }
  }
}