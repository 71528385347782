@import "variables";

/* Sub modal */

.gac-sub-interval {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 56px;

    &--webbuilding-on-change-plan {
        margin-bottom: 16px;

        @media (max-width: 767px) {
            margin-top: 48px;
        }

        & .gac-radio-group {
            @media (max-width: 992px) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                width: 100%;
            }

            @media (max-width: 767px) {
                column-gap: 24px;
                row-gap: 48px;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(2, 1fr);
            }

            & .gac-item {
                @media (max-width: 1200px) {
                    width: 140px;
                }

                @media (max-width: 992px) {
                    width: 100%;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    border: 1px solid #d9d9d9;
                }
            }
        }
    }

    &--webbuilding {
        @media (max-width: 576px) {
            padding-left: 16px !important;
            padding-right: 16px !important;
            width: 100% !important;
            max-width: 343px;
        }

        & .gac-radio-group {
            @media (max-width: 767px) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                width: 100%;
                column-gap: 24px;
                row-gap: 48px;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(2, 1fr);
            }

            & .gac-item {
                @media (max-width: 767px) {
                    width: 100%;
                    border: 1px solid #d9d9d9;
                }
            }
        }
    }

    .gac-radio-group {
        .gac-item {
            position: relative;
            height: 32px;
            color: $dark-text-color;
            border-color: $border-color;
            &.gac-active {
                &:before {
                    background-image: none;
                    background-color: $green-color;
                }
            }

            &__price-label {
                padding: 4px 9px;
                position: absolute;
                clip-path: url("#plan-price");
                z-index: 1;
                min-width: 117px;
                top: -24px;
                left: 50%;
                transform: translate(-50%, 0);
                background: #ff3666;
                font-size: 16px;
                font-weight: 700;
                line-height: 120%;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                pointer-events: none;

                @media (max-width: 768px) {
                    top: -20px;
                    padding: 2px 7px 4px;
                    min-width: 88px;
                    font-size: 15px;
                }
            }

            .gac-item-label {
                position: absolute;
                top: -11px;
                right: 7px;
                width: 32px;
                line-height: 20px;
                background-color: #00a99d;
                border-radius: 4px;
                color: #ffffff;
                font-size: 11px;
                font-weight: 600;
                text-align: center;
                &.annually {
                    width: 72px;
                    background-color: #ff3636;
                }
            }
        }
    }
    .gac-select {
        width: 120px;
        position: absolute;
        left: 50%;
        transform: translateX(calc(420px - 120px));
        z-index: 1;
    }
}
.gac-sub-plans {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 10px 18px;
    background-color: $white-color;

    &.gac-web-building-plans {
        padding: 0 16px 0;
        margin-bottom: 20px;
        max-width: 1216px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        align-items: stretch;
        gap: 20px;
        padding-bottom: 0;

        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 768px) {
            margin-top: 24px;
            margin-left: auto;
            margin-right: auto;
            max-width: 375px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
        }

        &--on-change-plan {
            @media (max-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width: 992px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .gac-item {
            display: flex;
            flex-direction: column;
            padding: 32px 16px 16px 16px;

            &.gac-item-custom {
                p {
                    justify-content: flex-start;
                }
            }
            .gac-title {
                min-height: 68px;
                line-height: 32px;
                margin-bottom: 24px;
                font-size: 26px;
            }
            .gac-sub-price {
                padding-top: 0;
                margin-bottom: 16px;
                font-size: 22px;
                span:not(.gac-active) {
                    font-weight: normal;
                }
            }
            .gac-total {
                margin-top: -10px;
                margin-bottom: 16px;
                color: #6f6f7a;
                font-size: 14px;
                text-align: center;
            }
            .gac-item-content {
                margin-bottom: 24px;
                text-align: center;
                .gac-or-svg {
                    margin: 8px 0;
                }
                .gac-item-content__p {
                    line-height: 24px;
                    font-size: 16px;
                }
                .gac-hint {
                    position: relative;
                    top: 3px;
                    display: inline-flex;
                    margin-left: 6px;
                    .gac-hint-wrap .gac-hint-text {
                        width: 270px;
                    }
                }
            }
            .gac-subscribe-btn {
                margin-top: auto;
                position: static;
                width: 100%;
            }

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                input {
                    top: -2px;
                }
            }
        }
    }

    &.gac-sub-plans--support {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 24px !important;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: stretch;
        gap: 16px;
        width: 100%;
        max-width: 1000px;

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .gac-item {
        position: relative;
        width: 230px;
        border: 1px solid $border-color;
        border-radius: 4px;
        padding: 33px 28px 32px;
        margin: 0 10px 30px;
        background-color: $white-color;
        transition: all 0.3s ease-in-out;
        box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.05);

        &.gac-item--support {
            padding: 32px 16px 16px 16px;
            margin: 0;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-direction: column;

            @media (max-width: 767px) {
                padding: 32px 16px 16px 16px !important;
            }

            & .gac-svg {
                align-self: center;
                width: 100%;
                max-width: 180px;
                aspect-ratio: 96/62;

                & img {
                    width: 100%;
                    height: auto;
                    pointer-events: none;
                }
            }

            &.gac-item-custom {
                p {
                    justify-content: flex-start;
                }
            }

            .gac-title {
                line-height: 32px;
                margin-top: 0;
                margin-bottom: 24px;
                font-size: 26px;
            }
            .gac-sub-price {
                padding-top: 0 !important;
                margin-bottom: 8px !important;
                font-size: 22px !important;

                span:not(.gac-active) {
                    font-weight: normal;
                }
            }
            .gac-total {
                margin-top: -10px;
                margin-bottom: 16px !important;
                color: #6f6f7a;
                font-size: 14px;
                text-align: center;
                position: static !important;
            }
            .gac-item-content {
                margin-top: auto;
                margin-bottom: 24px;
                text-align: center;
                .gac-or-svg {
                    margin: 8px 0;
                }
                .gac-item-content__p {
                    line-height: 24px;
                    font-size: 16px;
                }
                .gac-hint {
                    position: relative;
                    top: 3px;
                    display: inline-flex;
                    margin-left: 6px;
                    .gac-hint-wrap .gac-hint-text {
                        width: 270px;
                    }
                }
            }

            & .gac-subscribe-btn {
                position: static !important;

                @media (max-width: 767px) {
                    align-self: center;
                }
            }

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                input {
                    top: -2px;
                }
            }
        }

        &__discount-image {
            position: absolute;
            top: -29px;
            right: 27px;
        }
        .gac-label {
            display: flex;
            justify-content: center;
            position: absolute;
            left: 0;
            right: 0;
            top: -25px;
        }
        .gac-svg {
            margin-top: 0;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            position: static !important;

            img {
                width: auto;
                max-width: 100%;
            }
        }
        .gac-title {
            line-height: 42px;
            margin-bottom: 34px;
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            position: static !important;
        }
        p {
            line-height: 24px;
            font-size: 15px;
            .gac-free-ideas {
                color: $green-color;
                font-weight: bold;
            }
            input {
                position: relative;
                top: -4px;
                width: 36px;
                height: 20px;
                margin-right: 10px;
                padding: 0 2px;
                border: 0;
                border-bottom: 1px solid $dark-text-color;
                color: $dark-text-color;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                &.gac-invalid {
                    border-color: $red-color;
                }
            }
        }
        .gac-sub-level-wrap {
            line-height: 24px;
            font-size: 15px;
        }
        .gac-sub-level {
            position: relative;
            padding-right: 18px;
            line-height: 20px;
            cursor: pointer;
            &::before {
                content: "";
                border-bottom: 1px solid $dark-text-color;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &::after {
                content: "";
                position: absolute;
                right: 3px;
                top: 9px;
                width: 8px;
                height: 4px;
                background: url("../../images/sprite.svg") -140px -715px;
            }
        }
        .gac-sub-level-options {
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
            width: 72px;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 27px;
            background-color: #ffffff;
            z-index: 1;
            div {
                font-size: 14px;
                line-height: 21px;
                padding: 2px 0 1px 5px;
                cursor: pointer;
                transition: color 0.3s ease-in-out;
                &:hover {
                    color: #fbb03b;
                }
            }
            div + div {
                border-top: 1px solid #f7f7f7;
            }
        }
        .gac-sub-price {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 36px;
            padding-top: 40px;
            color: $dark-text-color;
            font-size: 24px;
            position: static !important;

            .gac-active {
                font-weight: bold;
            }
        }

        & .gac-total {
            margin-top: 0;
        }

        .gac-sub-full-price {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 8px;
            margin-top: -10px;
            margin-bottom: 10px;
            span {
                line-height: 20px;
                margin: 0 1px;
                color: $light-text-color;
                font-size: 14px;
            }
        }
    }
}
.gac-custom-sub-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 904px;
    .gac-item-custom-sub {
        flex: 1 1 auto;
        max-width: calc(50% - 10px);
        position: relative;
        padding: 16px;
        border: 1px solid $border-color;
        border-radius: 4px;
        background-color: $white-color;
        transition: all 0.3s ease-in-out;
        box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.05);
        .gac-svg {
            position: absolute;
            top: 16px;
            right: 16px;
        }
        .gac-title {
            line-height: 48px;
            font-size: 26px;
            font-weight: 700;
        }
        .gac-sub-input {
            line-height: 36px;
            margin-bottom: 8px;
            font-size: 22px;
            input {
                position: relative;
                top: -1px;
                width: 62px;
                height: 24px;
                margin: 0 4px;
                text-align: center;
                border: 0;
                border-bottom: 1px solid $dark-text-color;
                color: #ff9f00;
                font-size: 20px;
                font-weight: bold;
                &.gac-invalid {
                    border-color: $red-color;
                }
            }
        }
        .gac-total {
            margin-top: -4px;
            margin-bottom: 12px;
            color: #6f6f7a;
            font-size: 14px;
        }
        .gac-sub-hours {
            display: flex;
            flex-direction: column;
            strong,
            .gac-sub-level-hours {
                line-height: 24px;
                font-size: 16px;
            }
            .gac-or-svg {
                margin: 8px 0;
            }
            span {
                font-weight: bold;
            }
        }
        .gac-subscribe-btn {
            position: absolute;
            right: 16px;
            bottom: 16px;
            width: 186px;
        }
    }
}
.gac-sub-desc {
    .gac-title {
        line-height: 26px;
        margin-bottom: 32px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
    .gac-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 840px;
        margin-left: auto;
        margin-right: auto;
        .gac-item {
            position: relative;
            display: flex;
            width: calc(50% - 40px);
            padding-left: 40px;
            margin-bottom: 33px;
            p {
                flex: 1 1 auto;
                line-height: 24px;
            }
            i {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0;
                &.gac-icon-1 {
                    top: 4px;
                    left: 4px;
                    width: 26px;
                    height: 26px;
                }
                &.gac-icon-2 {
                    top: 7px;
                    left: 6px;
                    width: 24px;
                    height: 24px;
                }
                &.gac-icon-3 {
                    top: 6px;
                    left: 6px;
                    width: 22px;
                    height: 26px;
                }
                &.gac-icon-4 {
                    top: 2px;
                    left: 5px;
                    width: 25px;
                    height: 28px;
                }
            }
        }
    }
}
.gac-sub-btns {
    display: flex;
    justify-content: center;
    .gac-btn {
        margin: 0 5px;
    }
}
.gac-card-dropin {
    max-width: 298px;
    margin: 0 auto;
    padding: 20px 0;
    .gac-sub-dropin-wrap {
        margin-bottom: 20px;
    }
    .gac-dropin-btns {
        display: flex;
        justify-content: center;
        .gac-btn {
            width: 100%;
        }
    }
}

/* Subs page */
.gac-subscriptions-page {
    padding-top: 80px;
    padding-bottom: 130px;
    &.gac-mobile-view {
        padding-top: 72px;
        padding-bottom: 30px;
    }
    &__title {
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: bold;
    }
    .gac-table {
        span {
            &[data-name="talent"] {
                flex: 0 0 150px;
                white-space: nowrap;
                &.gac-subscription-webbuilders {
                    white-space: pre-wrap;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                }
            }
            &[data-name="plan"] {
                white-space: nowrap;
            }
            &[data-name="amount"] {
                flex: 0 0 100px;
            }
            &[data-name="interval"] {
                flex: 0 0 130px;
            }
            &[data-name="next-payment"] {
                flex: 0 0 130px;
            }
            &[data-name="payment-method"] {
                flex: 0 0 160px;
                text-transform: capitalize;
            }
            &[data-name="status"] {
                flex: 0 0 180px;
            }
        }
        .gac-table-item {
            span {
                &[data-name="interval"] {
                    text-transform: lowercase;
                }
            }
        }
    }
}
.gac-cta-banners {
    display: flex;
    justify-content: space-between;
    padding-top: 130px;
    &__item {
        flex: 0 0 360px;
        position: relative;
        padding: 47px 20px 26px;
        background-color: $white-color;
        border: 1px solid $green-color;
        border-radius: 2px;
        text-align: center;
        h3 {
            margin: 0;
            line-height: 26px;
            font-weight: bold;
        }
        p {
            line-height: 26px;
            a {
                font-size: 15px;
            }
        }
    }
    &__img {
        position: absolute;
        top: -80px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        img {
            height: 112px;
            width: auto;
        }
    }
}
.gac-table {
    margin-bottom: 24px;
    .gac-table-head {
        display: flex;
        span {
            height: 72px;
            line-height: 70px;
            padding: 0 15px 0 0;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            font-size: 15px;
            background-color: $white-color;
            user-select: none;
            &:first-child {
                padding-left: 23px;
                border-left: 1px solid $border-color;
                border-radius: 2px 0 0 0;
            }
            &:last-child {
                border-right: 1px solid $border-color;
                border-radius: 0 2px 0 0;
            }
        }
    }
    .gac-table-item {
        display: flex;
        text-decoration: none;
        font-size: 15px;
        &.cancelled {
            span {
                color: #9c9b9b;
            }
        }
        &:hover {
            color: $dark-text-color;
            span {
                background-color: #e2e7eb;
            }
        }
        &:last-child {
            span {
                border-bottom: 1px solid $border-color;
                &:first-child {
                    border-radius: 0 0 0 2px;
                }
                &:last-child {
                    border-radius: 0 0 2px 0;
                }
            }
        }
        &.gac-with-btn {
            padding: 2px 0 14px;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            background-color: $white-color;
            justify-content: center;
        }
        &__links {
            padding: 23px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $border-color;
            border-top: 0;
            background-color: $white-color;
            i {
                display: flex;
                margin-right: 12px;
            }
            a {
                font-size: 14px;
                font-weight: 600;
            }
        }
        &.gac-empty {
            text-align: center;
        }
        span {
            height: 70px;
            line-height: 68px;
            padding: 0 15px 0 0;
            font-size: 15px;
            background-color: $white-color;
            transition: background-color 0.3s ease;
            &:first-child {
                padding-left: 23px;
                border-left: 1px solid $border-color;
            }
            &:last-child {
                border-right: 1px solid $border-color;
            }
        }
        .gac-add-sub-btn {
            line-height: 28px;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .gac-table-item:not(.cancelled) {
        span[data-name="account"],
        span[data-name="talent"] {
            font-weight: bold;
        }
    }
    .gac-table-head + .gac-with-btn {
        padding-top: 10px;
    }
    span {
        &[data-name="account"] {
            flex: 1 1 auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &[data-name="plan"] {
            flex: 0 0 170px;
        }
        &[data-name="bundle"] {
            flex: 1 1 auto;
        }
        &[data-name="cost"] {
            flex: 0 0 130px;
        }
        &[data-name="period"] {
            flex: 0 0 130px;
        }
        &[data-name="payment"] {
            flex: 0 0 140px;
        }
        &[data-name="method"] {
            flex: 0 0 140px;
        }
        &[data-name="status"] {
            flex: 0 0 110px;
        }
        &[data-name="discount"] {
            flex: 0 0 100px;
        }
        &[data-name="balance"] {
            flex: 0 0 175px;
        }
        .gac-payment-failed {
            font-style: normal;
            color: $red-color;
        }
    }
}

/* Single sub page */
.gac-subscription-single {
    padding-top: 88px;
    padding-bottom: 130px;
    &.gac-mobile-view {
        padding-top: 72px;
        padding-bottom: 30px;
    }
    .gac-sub-plans,
    .gac-design-plans {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
    }
    .gac-sub-plans:not(.gac-web-building-plans) .gac-item {
        width: 208px;
        padding: 33px 16px 20px;
    }
    .gac-subscriptions-list {
        display: flex;
        align-items: flex-start;
        min-height: 21px;
        padding: 5px 0 0 0;
        margin-bottom: 33px;
    }
    .gac-design-plans {
        align-items: start;
        flex-wrap: wrap;
        justify-content: space-between;
        width: auto;
        margin: 0 auto;
        &-item {
            width: 220px;
        }
    }
    .gac-assistants-plans {
        padding-top: 60px;
        &__item {
            margin: 0 11px;
            &-btn.gac-current-btn {
                background-color: #e9eff3;
                border-color: #e9eff3;
                cursor: default;
                &:hover {
                    color: $dark-text-color;
                }
            }
        }
    }
}

/* Elements */
/* Radio group */
.gac-radio-group {
    display: flex;
    .gac-item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 172px;
        height: 48px;
        border: 1px solid $border-color;
        border-left: 0;
        margin-bottom: 8px;
        background-color: $white-color;
        color: $light-text-color;
        cursor: pointer;
        user-select: none;
        text-transform: capitalize;

        &--website-developers {
            text-transform: unset;
        }

        &:first-child {
            border-radius: 2px 0 0 2px;
            border-left: 1px solid $border-color;
        }
        &:last-child {
            border-radius: 0 2px 2px 0;
        }
        &.gac-active {
            background-color: $white-color;
            &:before {
                position: absolute;
                left: -1px;
                bottom: -1px;
                width: calc(100% + 2px);
                height: 2px;
                border-radius: 2px;
                background: $green-color;
                content: "";
            }
        }
    }
    &.gac-v2 {
        .gac-item {
            width: auto;
            padding: 0 14px;
            text-transform: none;
        }
    }
}
.gac-acc-list {
    margin-bottom: 20px;
    &__item {
        margin-bottom: 8px;
        &.disabled {
            opacity: 0.5;
            label {
                cursor: default;
            }
        }
        input {
            display: none;
        }
        label {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            span {
                margin-right: 6px;
            }
        }
    }
}
.gac-list {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &__label {
        position: relative;
        line-height: 18px;
        margin-right: 6px;
        font-size: 20px;
        font-weight: bold;
        text-decoration: none;
        &:hover {
            &:before {
                background-color: $green-color;
            }
        }
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: calc(100% - 5px);
            height: 1px;
            background-color: #1e1e1e;
            content: "";
            transition: background-color 0.3s ease-in-out;
        }
    }
}

/* Payment methods */
.gac-payment-methods {
    .gac-btn-wrap {
        display: flex;
        justify-content: center;
        .gac-btn {
            width: 100%;
            max-width: 298px;
            margin: 0 auto;
        }
    }
}
.gac-stripe-form {
    width: 100%;
    max-width: 430px;
    margin: 0 auto 30px;
}

/* Design Plans */
.gac-plans-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
        line-height: 28px;
        padding: 40px 20px;
        background-color: $white-color;
        color: $dark-text-color;
        font-size: 24px;
        font-weight: bold;
        span {
            color: $green-color;
        }
    }
    .gac-sub-interval {
        margin-bottom: 0;
        padding: 0 20px 28px;
        background-color: $white-color;
        width: auto;

        .gac-select {
            left: auto;
            right: 0;
            transform: none;
        }
    }
    .gac-radio-group {
        .gac-item {
            height: 32px;
            color: $dark-text-color;
            border-color: $border-color;
            &.gac-active {
                &:before {
                    background-image: none;
                    background-color: $green-color;
                }
            }
        }
    }
    .gac-bundles {
        background-color: $white-color;
    }
    .gac-bundle-item-v2 {
        &__amount {
            margin-bottom: 10px;
        }
        &__discount {
            margin-bottom: 16px;
        }
    }
}
.gac-design-plans__discount {
    margin-bottom: 40px;
    background-color: $white-color;
    img {
        cursor: pointer;
    }
}
.gac-design-plans {
    display: flex;
    justify-content: space-between;
    width: 1064px;
    padding: 20px;
    background-color: $white-color;

    &--hourly-designers {
        width: 1020px;
    }

    &.gac-plans-motion {
        margin-top: 100px;
    }

    &-item {
        width: 320px;
        border: 1px solid $border-color;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
        margin-bottom: 30px;
        box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.05);
        background-color: $white-color;

        &:hover {
            border-color: $green-color;
            .gac-design-plans-item__head {
                border-color: $green-color;
            }
        }

        &.motion {
            .gac-design-plans-item__head {
                min-height: 185px;
                padding-top: 34px;
            }
            .gac-design-plans-item__epiic {
                left: 63px;
                top: -120px;
                &.monthly {
                    top: -101px;
                }
                &.annually {
                    top: -123px;
                }
            }
            .gac-design-plans-item__content {
                padding-top: 0;
            }
        }

        &--hourly-designers {
            width: 230px;

            & .gac-design-plans-item__head {
                padding: 24px 16px 9px;
                text-align: center;

                & .gac-sub-input {
                    line-height: 36px;
                    margin-bottom: 4px;
                    font-size: 28px;
                    font-weight: normal;
                    input {
                        position: relative;
                        top: -1px;
                        width: 62px;
                        height: 24px;
                        margin: 0 4px;
                        text-align: center;
                        border: 0;
                        border-bottom: 1px solid $dark-text-color;
                        color: #00ab9c;
                        font-size: 24px;
                        font-weight: bold;
                        &.gac-invalid {
                            border-color: $red-color;
                        }
                    }
                }

                & i {
                    display: flex;
                    position: absolute;
                    left: 50%;
                    top: -24px;
                    transform: translateX(-50%);
                }
            }

            & .gac-design-plans-item__epiic {
                position: absolute;
                left: 50%;
                display: flex;
                transform: translateX(-50%);
            }

            & .gac-design-plans-item__svg {
                justify-content: start;
            }
        }

        &__discount-image {
            position: absolute;
            top: -29px;
            right: 27px;
        }

        &__head {
            position: relative;
            line-height: 40px;
            padding: 24px 27px 9px;
            color: $dark-text-color;
            font-size: 28px;
            font-weight: bold;
            transition: all 0.3s ease-in-out;
            span {
                font-weight: normal;
            }
            .gac-small {
                font-size: 16px;
            }
            .gac-old-amount {
                text-decoration: line-through;
                font-size: 16px;
            }
            .gac-new-amount {
                color: $green-color;
            }
            .gac-total {
                display: block;
                line-height: 20px;
                color: $light-text-color;
                font-size: 16px;
            }
            i {
                display: flex;
                position: absolute;
                left: 26px;
                top: -24px;
            }
        }

        &__epiic {
            position: absolute;
            left: 31px;
            top: -65px;
            display: flex;
            justify-content: flex-end;
        }

        &__svg {
            position: absolute;
            left: 0;
            right: 0;
            padding-right: 15px;
            top: -25px;
            display: flex;
            justify-content: flex-end;
        }

        &__content {
            padding: 18px 28px;

            &.gac-v2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                line-height: 22px;
                font-size: 16px;
                strong {
                    display: block;
                }
                p span {
                    font-weight: bold;
                }
                .gac-or-svg {
                    display: flex;
                    margin: 8px 0;
                }
                .gac-design-plans-item__btn,
                .gac-design-plans-item__current-btn {
                    margin-top: 24px;
                }
            }

            strong {
                display: inline-block;
                line-height: 30px;
                color: $dark-text-color;
                a {
                    font-size: 15px;
                    font-weight: bold;
                }
            }
            ul {
                li {
                    line-height: 27px;
                    color: $dark-text-color;
                    .gac-hint {
                        display: inline-flex;
                        position: relative;
                        top: 3px;
                        margin-left: 6px;
                        .gac-hint-wrap .gac-hint-text {
                            width: 270px;
                        }
                    }
                }
            }
        }

        &__p {
            line-height: 30px;
            color: $dark-text-color;
            .gac-hint {
                position: relative;
                top: 3px;
                display: inline-flex;
                margin-left: 6px;
                .gac-hint-wrap .gac-hint-text {
                    width: 270px;
                }
            }
            a {
                line-height: 30px;
                font-size: 15px;
            }
        }

        &__btn,
        &__current-btn {
            margin-bottom: 12px;
            display: block;
            width: 100%;
            height: 48px;
            line-height: 46px;
            border: 1px solid $btn-yellow-color;
            border-radius: 4px;
            color: $dark-text-color;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                background-color: $btn-yellow-color;
                color: $white-color;
            }
        }

        &__current-btn {
            background-color: $disabled-color;
            border-color: $disabled-color;
            cursor: default;
            &:hover {
                background-color: $disabled-color;
                color: $dark-text-color;
            }
        }

        &__hint {
            position: relative;
            display: inline-block;
            margin-top: 8px;
            line-height: 18px;
            color: $dark-text-color;
            font-size: 12px;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
            .gac-hint-v2 {
                position: absolute;
                left: calc(100% + 24px);
                top: -280px;
                padding: 0 24px;
                border: 1px solid $green-color;
                background-color: $white-color;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
                border-radius: 2px;
                cursor: default;
                z-index: 2;
                &:before {
                    position: absolute;
                    left: -8px;
                    top: 280px;
                    width: 16px;
                    height: 16px;
                    background-color: $white-color;
                    border: 1px solid $green-color;
                    border-bottom-color: transparent !important;
                    border-left-color: transparent !important;
                    transform: rotate(-135deg);
                    content: "";
                }
                &__wrap {
                    display: flex;
                    h3 {
                        line-height: 26px;
                        margin: 36px 0 24px;
                        color: $green-color;
                        font-size: 20px;
                        font-weight: bold;
                        white-space: nowrap;
                    }
                    p {
                        line-height: 26px;
                        color: $dark-text-color;
                        font-size: 15px;
                        white-space: nowrap;
                        a {
                            font-size: 15px;
                            color: $green-color;
                        }
                    }
                }
                &__col-1 {
                    padding-right: 32px;
                    padding-bottom: 24px;
                    border-right: 1px solid $green-color;
                    .gac-hint-v2-content {
                        display: flex;
                        p:first-child {
                            margin-right: 60px;
                        }
                    }
                }
                &__col-2 {
                    padding: 0 0 0 24px;
                }
            }
        }
    }
}

/* Assistants plans */
.gac-assistants-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 954px;
    padding: 20px 0;
    &__item {
        position: relative;
        flex: 0 0 288px;
        padding: 38px 24px 24px;
        margin: 0 15px;
        border: 1px solid $border-color;
        border-radius: 4px;
        background: $white-color;
        box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.05);
        &-icon {
            display: flex;
            position: absolute;
            top: -98px;
            left: 50%;
            margin-left: -62px;
        }
        &-title {
            line-height: 34px;
            margin-bottom: 16px;
            font-size: 28px;
            font-weight: bold;
            text-align: center;
        }
        &-price {
            line-height: 42px;
            margin-bottom: 16px;
            font-size: 28px;
            text-align: center;
            span {
                font-weight: bold;
            }
        }
        &-total {
            line-height: 20px;
            margin-top: -16px;
            margin-bottom: 16px;
            color: $light-text-color;
            text-align: center;
        }
        &-desc {
            line-height: 24px;
            margin-bottom: 24px;
            font-size: 16px;
            p .gac-hint {
                position: relative;
                top: 3px;
                display: inline-flex;
                margin-left: 6px;
                .gac-hint-wrap .gac-hint-text {
                    width: 270px;
                }
            }
        }
        &-btn {
            height: 48px;
            line-height: 46px;
            width: 100%;
            border: 1px solid #ff3666;
            border-radius: 4px;
            color: $dark-text-color;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                background-color: #ff3666;
                color: $white-color;
            }
        }
    }
}

/* Bundles */
.gac-bundles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 22px;
    .gac-bundle-item {
        position: relative;
        width: 200px;
        height: 315px;
        margin: 0 12px 24px;
        padding: 32px 16px 0;
        border: 1px solid $border-color;
        border-radius: 4px;
        text-align: center;
        .gac-subscribe-btn {
            min-width: inherit;
            width: 100%;
        }
    }
}
.gac-bundles-text {
    text-align: center;
    h3 {
        margin: 0 0 8px;
        font-size: 20px;
        font-weight: bold;
    }
    p {
        margin: 0;
        color: $light-text-color;
        font-size: 12px;
    }
}
.gac-bundle-item-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__most-popular {
        position: absolute;
        top: -17px;
        left: 50%;
        transform: translateX(-50%);
    }
    &__icon {
        margin-bottom: 17px;
    }
    &__name {
        line-height: 42px;
        font-size: 28px;
        font-weight: 700;
    }
    &__amount {
        line-height: 36px;
        margin-top: -7px;
        margin-bottom: 24px;
        color: $green-color;
        font-size: 24px;
        font-weight: 600;
    }
    &__save {
        line-height: 30px;
        font-size: 20px;
        font-weight: bold;
    }
    &__discount {
        line-height: 26px;
        margin-top: -3px;
        font-size: 17px;
    }
}
.gac-custom-bundle {
    background-color: $white-color;
    padding-bottom: 30px;
    p {
        line-height: 26px;
        margin-bottom: 16px;
        color: $light-text-color;
        font-size: 17px;
        font-weight: 600;
        text-align: center;
    }
    &__btn {
        display: flex;
        align-items: center;
        line-height: 24px;
        padding: 11px 14px 11px 11px;
        border: 1px solid $green-color;
        font-size: 20px;
        border-radius: 4px;
        user-select: none;
        cursor: pointer;
        svg {
            margin-right: 12px;
        }
    }
    &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 544px;
        padding: 9px 22px 9px 23px;
        border: 1px solid $green-color;
        box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
    }
    &__icon {
        position: absolute;
        top: -56px;
        left: 23px;
    }
    &__name {
        line-height: 42px;
        h3 {
            margin: 0;
            padding: 0;
            font-size: 28px;
        }
    }
    &__input {
        display: flex;
        align-items: center;
        line-height: 36px;
        margin-top: -2px;
        span {
            margin-right: 8px;
            color: $green-color;
            font-size: 24px;
        }
        input {
            width: 68px;
            height: 28px;
            padding: 0 6px;
            border: 1px solid $border-color;
            border-radius: 4px;
            color: $green-color;
            font-size: 16px;
            font-weight: bold;
        }
    }
    &__save {
        h3 {
            line-height: 30px;
            margin: 0 0 5px;
            font-size: 20px;
            font-weight: bold;
        }
        p {
            margin: 0;
            font-size: 17px;
            font-weight: 400;
        }
    }
    &__buy {
        .gac-button {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

/* Prepay */
.gac-prepay-wrap {
    position: relative;
    width: 100%;
    max-width: 214px;
    margin: 105px auto 0;
    padding: 30px 24px 24px;
    border: 1px solid #f7c300;
    border-radius: 4px;
    background-color: $white-color;
    &__icon {
        position: absolute;
        top: -82px;
        left: 50%;
        display: flex;
        justify-content: center;
        transform: translateX(-50%);
    }
    &__title {
        line-height: 42px;
        font-size: 28px;
        margin: 0 0 20px;
        text-align: center;
    }
    &__input-wrap {
        display: flex;
        align-items: flex-start;
        max-width: 102px;
        height: 32px;
        margin: 0 auto;
        padding: 0 4px 0 8px;
        margin-bottom: 25px;
        border: 1px solid #c4c4c4;
        border-radius: 4px;
    }
    &__currency {
        line-height: 30px;
        margin-right: 3px;
        color: #c4c4c4;
        font-size: 21px;
        font-weight: 600;
    }
    &__input {
        flex: 1 1 auto;
        min-width: 0;
        height: 28px;
        padding: 0;
        border: 0;
        color: $green-color;
        font-size: 20px;
        font-weight: 600;
        &::placeholder {
            color: #c4c4c4;
        }
    }
    .gac-btn {
        width: 100%;
        border-radius: 4px;
    }
}
