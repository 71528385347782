@import 'variables';

.gac-footer-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  border-top: 1px solid $border-color;
  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 79px;
    //padding-top: 32px;
    .gac-footer__copyright {
      .gac-hint {
        color: $light-text-color!important;
      }
    }
    .gac-footer__copyright,
    .gac-footer__feedback {
      line-height: 26px;
      font-size: 12px;
      font-weight: 400;
      color: $light-text-color;
      a {
        color: $light-text-color;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .gac-footer__feedback {
      span {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $green-color;
          text-decoration: none;
        }
      }
    }
  }
}