@import 'variables';

.gac-manage {
  padding-top: 136px;
  padding-bottom: 130px;
  &.gac-manage-empty {
    display: flex;
    flex-direction: column;
    &.gac-mobile-view {
      padding-bottom: 15px;
    }
  }
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 30px;
    .gac-manage-search {
      margin-bottom: 16px;
    }
  }
  .gac-manage-search {
    display: flex;
    margin-bottom: 24px;
    transition: margin-bottom .2s ease;
    &.gac-filters-on {
      margin-bottom: 16px;
    }
    .gac-search-input-wrap {
      position: relative;
      flex: 1 1 auto;
      .gac-search-input {
        width: 100%;
        height: 48px;
        line-height: normal;
        padding: 0 54px 0 15px;
        border: 1px solid $border-color;
        border-radius: 2px;
        color: $dark-text-color;
        font-size: 15px;
        box-shadow: none!important;
        background-clip: padding-box;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $light-text-color;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $light-text-color;
          opacity: 1;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $light-text-color;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $light-text-color;
          opacity: 1;
        }
      }
      .gac-search__total {
        position: absolute;
        right: 56px;
        top: 12px;
        line-height: 22px;
        color: $light-text-color;
        font-size: 15px;
      }
      .gac-search__btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        background: url('../../images/sprite.svg') no-repeat -115px 0;
        cursor: pointer;
      }
    }
    .gac-filters-btn {
      margin-left: 16px;
    }
  }
  .gac-manage-filters {
    display: flex;
    .gac-filter-item {
      width: 215.2px;
      margin-right: 16px;
      margin-bottom: 24px;
      &:last-child {
        margin-right: 0;
      }
      .gac-filter-item__menu {
        //width: 100%;
      }
    }
  }
  .gac-manage-content {
    position: relative;
    z-index: 1;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    &__head {
      display: flex;
      position: relative;
      border-bottom: 1px solid $border-color;
      span {
        display: flex;
        height: 71px;
        line-height: 24px;
        padding: 23px 20px 24px 0;
        cursor: pointer;
        &.gac-manage-content__head-id {
          flex: 0 0 96px;
          padding-left: 23px;
        }
        &.gac-manage-content__head-topic {
          flex: 1 1 auto;
        }
        &.gac-manage-content__head-format {
          flex: 0 0 190px;
        }
        &.gac-manage-content__head-amount {
          flex: 0 0 120px;
        }
        &.gac-manage-content__head-writer {
          flex: 0 0 170px;
        }
        &.gac-manage-content__head-order {
          flex: 0 0 100px;
        }
        &.gac-manage-content__head-status {
          flex: 0 0 215px;
          &.gac-completed-page {
            flex: 0 0 180px;
          }
        }
        &.gac-manage-content__head-due {
          flex: 0 0 110px;
        }
        &.gac-manage-content__head-rating {
          flex: 0 0 100px;
        }
        &.gac-manage-content__head-approval {
          flex: 0 0 140px;
        }
        .gac-manage-content__head-icon {
          position: relative;
          width: 24px;
          height: 24px;
          background: url('../../images/sprite.svg') -60px -260px;
          top: 1px;
          &.gac-sorting-active.gac-sorting-asc {
            background-position: -60px -230px;
          }
          &.gac-sorting-active {
            background-position: -85px -230px;
          }
        }
      }
      .gac-disable-sorting-mode {
        position: absolute;
        top: 50%;
        right: 21px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        cursor: pointer;
      }
    }
    .gac-manage-droppable {
      .gac-manage-project-wrap {
        border-bottom: 1px solid #E9EFF3;
        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
    .gac-manage-project-wrap {
      position: relative;
      background-color: transparent;
      transition: background-color .3s ease;
      &:hover {
        background-color: #e2e7eb;
        color: inherit;
        .gac-manage-project {
          span.gac-manage-project-status {
            .gac-manage-project-status-wrapper {
              background-color: $white-color;
            }
          }
          span.gac-manage-project-writer {
            .gac-writer-avatar {
              background-color: $white-color;
            }
          }
          .gac-manage-project-topic {
            .gac-project-tag {
              background-color: $white-color;
              border-color: $white-color;
            }
            .gac-messages {
              i {
                border-color: $border-color;
              }
            }
          }
        }
      }
    }
    .gac-manage-project {
      position: relative;
      display: flex;
      cursor: pointer;
      text-decoration: none;
      font-size: 15px;
      &:active,
      &:hover {
        color: inherit;
      }
      &:focus {
        color: inherit;
      }
      span,
      a.gac-manage-project-topic {
        line-height: 32px;
        padding: 19px 20px 19px 0;
        &.gac-manage-project-topic {
          position: relative;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex: 1 1 auto;
          line-height: 22px;
          padding: 13px 20px 13px 96px;
          & > div {
            overflow: visible!important;
          }
          .gac-project-tag {
            display: inline;
            line-height: 22px;
            padding: 0 9px;
            margin-top: 2px;
            margin-bottom: 2px;
            margin-right: 4px;
            border: 1px solid $border-color;
            border-radius: 12px;
            font-size: 12px;
            color: $light-text-color;
            transition: border-color .3s ease, background-color .3s ease;
            white-space: nowrap;
          }
          .gac-messages + .gac-project-tag {
            margin-left: 16px;
          }
          .gac-messages {
            display: inline-block;
            position: relative;
            top: 4px;
            width: 20px;
            height: 20px;
            margin-left: 15px;
            background: url('../../images/sprite.svg') -60px -290px;
            i {
              display: block;
              position: absolute;
              top: -12px;
              left: 10px;
              min-width: 21px;
              height: 21px;
              line-height: 17px;
              padding: 0 4px;
              border: 2px solid $white-color;
              background-color: $red-color;
              color: $white-color;
              font-size: 12px;
              font-style: normal;
              border-radius: 12px;
              text-align: center;
              transition: border-color .3s ease;
            }
          }
          .gac-project-type {
            position: absolute;
            left: 73px;
            top: 50%;
            margin-top: -8px;
          }
        }
        &.gac-manage-project-format {
          display: flex;
          align-items: center;
          flex: 0 0 190px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.gac-manage-project-amount {
          display: flex;
          align-items: center;
          flex: 0 0 120px;
          padding-right: 18px;
          i.gac-level-premium {
            width: 16px;
            height: 15px;
            margin-left: 6px;
            background: url('../../images/sprite.svg') 0px -455px;
          }
          i.gac-level-expert {
            width: 20px;
            height: 12px;
            margin-left: 6px;
            background: url('../../images/sprite.svg') -35px -230px;
          }
        }
        &.gac-manage-project-writer {
          display: flex;
          align-items: center;
          flex: 0 0 170px;
          .gac-img {
            max-width: 32px;
            height: 32px;
            margin: 0 9px 0 2px;
            border-radius: 100%;
          }
          .gac-writer-avatar {
            flex: 0 0 32px;
            height: 32px;
            margin: 0 9px 0 2px;
            background: url('../../images/sprite.svg') 0 -250px #e9eff3;
            border-radius: 100%;
            transition: background-color .3s ease;
          }
          .gac-writer-name {
            flex: 1 1 auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.gac-manage-project-order {
          display: flex;
          align-items: center;
          flex: 0 0 100px;
          padding-right: 40px;
          white-space: nowrap;
          &.gac-order-negative {
            color: $red-color;
          }
        }
        &.gac-manage-project-status {
          display: flex;
          align-items: center;
          flex: 0 0 215px;
          .gac-manage-project-status-wrapper {
            width: 65px;
            height: 6px;
            margin-right: 16px;
            border-radius: 4px;
            background-color: $border-color;
            transition: background-color .3s ease;
            .gac-manage-project-status__bar {
              height: 6px;
              border-radius: 4px;
              &.approval {
                width: 100%;
                background-color: $red-color;
              }
              &.editing {
                width: 70%;
                background-color: $blue-color;
              }
              &.designing, &.writing, &.revision {
                width: 40%;
                background-color: $blue-color;
              }
              &.matching,
              &.queued {
                width: 20%;
                background-color: $green-color;
              }
              &.pitching {
                width: 20%;
                background-color: $red-color;
              }
            }
          }
          .gac-manage-project-status-lable {
            text-transform: capitalize;
          }
          &.gac-completed-page {
            flex: 0 0 180px;
            .gac-manage-project-status-wrapper {
              width: 10px;
              height: 10px;
              margin-right: 8px;
              border-radius: 100%;
            }

            &.published {
              color: $green-color;
              .gac-manage-project-status-wrapper {
                background-color: $green-color;
              }
            }
            &.approved,
            &.auto {
              color: $green-color;
              .gac-manage-project-status-wrapper {
                background-color: $green-color;
              }
            }
            &.cancelled {
              color: $grey-color;
              .gac-manage-project-status-wrapper {
                background-color: $grey-color;
              }
            }
          }
        }
        &.gac-manage-project-due {
          display: flex;
          align-items: center;
          flex: 0 0 110px;
          padding-right: 40px;
          white-space: nowrap;
          &.gac-order-negative {
            color: $red-color;
          }
          .gac-change-queue {
            min-height: 24px;
            margin-left: 8px;
          }
        }
        &.gac-manage-project-rating {
          display: flex;
          align-items: center;
          flex: 0 0 100px;
          .gac-manage-project-feedback {
            position: relative;
            top: -1px;
            display: inline-block;
            vertical-align: middle;
          }
        }
        &.gac-manage-project-approval {
          display: flex;
          align-items: center;
          flex: 0 0 140px;
          padding-right: 40px;
          white-space: nowrap;
        }
        .gac-revision-label {
          display: inline-block;
          line-height: 20px;
          padding: 0 6px;
          margin-left: 4px;
          background-color: $blue-color;
          color: $white-color;
          font-size: 12px;
          border-radius: 2px;
        }
      }
    }
  }
}
.gac-my-team {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: relative;
  .gac-my-team-item {
    position: relative;
    //position: absolute;
    width: 36px;
    height: 36px;
    .gac-my-team-item-avatar {
      width: 36px;
      height: 36px;
      border: 2px solid #ffffff;
      background: url('../../images/sprite.svg') 0 -250px #e9eff3;
      border-radius: 100%;
      transition: background-color .3s ease;
    }
    .gac-my-team-item-name {
      display: none;
      position: absolute;
      top: -40px;
      left: 50%;
      line-height: 22px;
      padding: 4px 10px 6px;
      background-color: $dark-text-color;
      color: $white-color;
      font-size: 15px;
      white-space: nowrap;
      border-radius: 4px;
      transform: translateX(-50%);
      z-index: 20;
      &:before {
        position: absolute;
        left: 50%;
        bottom: -6px;
        margin-left: -6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $dark-text-color;
        content: "";
      }
    }
    &:hover {
      .gac-my-team-item-name {
        display: block;
      }
    }
    &:nth-child(1) {
      order: 2;
      margin-left: -16px;
    }
    &:nth-child(2) {
      order: 3;
      margin-left: -16px;
    }
    &:nth-child(3) {
      order: 4;
    }
  }
  .gac-my-team-rest {
    position: relative;
    margin-left: 6px;
    line-height: 36px;
    order: 1;
    &:empty {
      margin-left: 0;
    }
    .gac-my-team-rest-list {
      display: none;
      position: absolute;
      left: 50%;
      line-height: 22px;
      padding: 4px 10px 6px;
      background-color: $dark-text-color;
      color: $white-color;
      font-size: 15px;
      white-space: nowrap;
      border-radius: 4px;
      transform: translateX(-50%);
      z-index: 20;
      &.bottom {
        &:before {
          top: -6px;
          border-top: 0!important;
          border-bottom: 6px solid $dark-text-color;
        }
      }
      &:before {
        position: absolute;
        left: 50%;
        bottom: -6px;
        margin-left: -6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $dark-text-color;
        content: "";
      }
    }
    &:hover {
      .gac-my-team-rest-list {
        display: block;
      }
    }
  }
  &.gac-my-team-2 {
    .gac-my-team-item:nth-child(2) {
      margin-left: 0;
    }
  }
  &.gac-my-team-less {
    .gac-my-team-item:nth-child(1) {
      .gac-my-team-item-name {
        left: auto;
        right: 0;
        transform: translateX(0);
        &:before {
          left: auto;
          right: 12px;
          margin-left: -6px;
        }
      }
    }
  }
  .gac-modal-project-field & {
    height: 36px;
    padding-top: 1px;
    align-items: center;
    .gac-my-team-item {
      width: 26px;
      height: 26px;
      .gac-my-team-item-avatar {
        width: 26px;
        height: 26px;
        background-position: 0 -200px;
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-left: -12px;
      }
    }
    .gac-my-team-rest {
      line-height: 26px;
    }
  }
}
.gac-feedback-text {
  width: max-content;
  max-width: 560px;
  padding: 10px 15px 12px;
  position: absolute;
  top: 34px;
  left: 50%;
  line-height: 22px;
  background-color: $dark-text-color;
  font-size: 15px;
  color: $white-color;
  z-index: 10;
  transform: translateX(-50%);
  border-radius: 4px;
}
.gac-project-type {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 16px;
  height: 16px;
  //line-height: 16px;
  border-radius: 100%;
  color: $white-color;
  font-size: 10px;
  font-weight: bold;
  //text-align: center;
  text-transform: uppercase;
  &:hover {
    .gac-type-name {
      opacity: 1;
      z-index: 2;
      visibility: visible;
    }
  }
  i {
    position: absolute;
    left: -55px;
    color: $dark-text-color;
    font-style: normal;
    font-size: 15px;
    font-weight: normal;
  }
  .gac-type-name {
    position: absolute;
    top: -37px;
    left: 50%;
    line-height: 18px;
    padding: 4px 10px 6px;
    background-color: $dark-text-color;
    color: $white-color;
    font-size: 12px;
    font-weight: normal;
    border-radius: 4px;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    text-transform: initial;
    &:before {
      position: absolute;
      left: 50%;
      bottom: -5px;
      margin-left: -7px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 5px solid $dark-text-color;
      content: "";
    }
  }
}
.gac-project-actions {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  .gac-actions-icon {
    position: relative;
    width: 36px;
    height: 18px;
    padding-right: 18px;
    i {
      position: absolute;
      left: 7px;
      top: 0;
      width: 4px;
      height: 4px;
      background-color: $grey-color;
      border-radius: 100%;
      &:nth-child(2) {
        top: 50%;
        margin-top: -2px;
      }
      &:nth-child(3) {
        top: auto;
        bottom: 0;
      }
    }
  }
  ul {
    position: absolute;
    right: 15px;
    top: 100%;
    padding: 7px 0;
    margin-top: -20px;
    min-width: 120px;
    background-color: #F1F1F1;
    z-index: 10;
    border-radius: 2px;
    li {
      display: block;
      line-height: 22px;
      margin-bottom: 6px;
      background-color: transparent;
      color: $dark-text-color;
      transition: color .3s ease, background-color .3s ease;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $dark-text-color;
        font-size: 15px;
        text-decoration: none;
      }
      svg {
        margin-right: 12px;
      }
    }
  }
  &__close {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 8px!important;
    padding: 8px;
    margin: 0!important;
    cursor: pointer;
    svg {
      margin: 0!important;
    }
  }
}
.gac-change-queue {
  display: flex;
  i {
    cursor: pointer;
  }
}
.gac-sorting-icon {
  position: absolute;
  right: 21px;
  top: 50%;
  width: 24px;
  height: 12px;
  margin-top: -6px;
  cursor: pointer;
  i {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: $green-color;
    &:nth-child(2) {
      top: 5px;
    }
    &:nth-child(3) {
      top: 10px;
    }
  }
}
