.web-builders-custom-subscription {
    padding: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 7px;
    width: 520px;
    border-radius: 4px;
    border: 1px solid #dadada;
    background: #fff;
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 10;
    transition: all 0.3s ease-in-out 0s;
    font-size: 16px;

    @media (max-width: 768px) {
        padding-top: 0;
        padding-right: 15px;
        max-width: unset;
        min-width: unset;
        width: 100%;
        max-width: 343px;
        flex-direction: column;
        align-items: stretch;
        gap: 16px;
    }

    &--on-change-plan {
        & .web-builders-custom-subscription__name {
            font-size: 23px;
        }
    }

    &__body {
        flex: none;

        @media (max-width: 768px) {
            margin-top: -15px;
            display: flex;
            align-items: start;
            justify-content: space-between;
        }
    }

    &__description {
    }

    &__icon {
        margin-bottom: 10px;

        @media (max-width: 768px) {
            margin-bottom: 21px;
        }
    }

    &__name {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 28px;
        line-height: 150%;

        @media (max-width: 1200px) {
            font-size: 24px;
            line-height: toProcent(24, 29);
        }
    }

    &__prices {
        @media (max-width: 768px) {
            margin-top: 29px;
        }
    }

    &__price {
        font-size: 22px;
        line-height: 150%;
        display: flex;
        align-items: center;

        &-left {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &-quarter {
            white-space: nowrap;
            max-width: 150px;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__input {
        padding: 0 4px;
        color: #00ab9c;
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        width: 64px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #1e1e1e;

        @media (max-width: 768px) {
            width: 56px;
            font-size: 20px;
        }
    }

    &__info {
    }

    &__specialists {
        margin-bottom: 34px;

        @media (max-width: 768px) {
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @media (max-width: 576px) {
            font-size: 14px;
        }

        &-offer {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 2px;
            line-height: 150%;

            @media (max-width: 768px) {
                line-height: toProcent(16, 19);
                width: fit-content;
            }

            &-value {
                white-space: nowrap;
                line-height: 150%;

                & span {
                    color: #00ab9c;
                    font-weight: 700;
                }
            }
        }

        &-separator {
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 16px;
            height: 16px;
            flex: none;

            @media (max-width: 768px) {
                margin-left: 14px;
                margin-right: 14px;
                width: 20px;
                height: 20px;
            }

            @media (max-width: 350px) {
                margin-left: 8px;
                margin-right: 8px;
            }

            & svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__btn.gac-subscribe-btn {
        padding: 13px 34px;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #ff3666;

        @media (max-width: 768px) {
            width: 100%;
        }

        &:hover {
            background-color: #ff3666;
            color: $white-color;
        }
    }
}
